export default {
  "language": "Portuguese",
  "m_campaign": "Campanha",
  "m_home": "Início",
  "m_compare": "Comparar",
  "m_challenges": "Desafios",
  "m_event": "Evento",
  "m_events": "Eventos",
  "m_charts": "Gráfico",
  "m_options": "Opções",
  "m_about": "Sobre",
  "m_vChanges": "{version} Mudanças",
  "m_library": "Biblioteca",
  "m_addLibrary": "Adicionar à biblioteca",
  "m_sharableLink": "Link compartilhável",
  "m_copy": "Copiar",
  "m_listCars": "Lista de carros",
  "m_type": "Tipo",
  "m_search": "Buscar",
  "m_searchCar": "Buscar carro",
  "m_searchTracks": "Buscar pista",
  "m_selectTrack": "Selecionar pista",
  "m_contributors": "Colaboradores",
  "m_colors": "Cores",
  "m_more3dot": "Mais...",
  "m_trackset": "Pistas",
  "m_clear": "Limpar",
  "m_clearCars": "Limpar carros",
  "m_load": "Carregar",
  "m_loadChart": "Carregar gráfico",
  "m_memory": "Memória",
  "m_image": "Imagem",
  "m_pleaseWait3dot": "Carregando...",
  "m_downloadPng": "Baixar PNG",
  "m_save": "Salvar",
  "m_cancel": "Cancelar",
  "m_login": "Logar",
  "m_gotoLogin": "Ir para Login",
  "m_emailVerification": "Verificação de e-mail",
  "m_username": "Usuário",
  "m_password": "Senha",
  "m_newPassword": "Nova senha",
  "m_repeatPassword": "Repetir senha",
  "m_repeat": "Repetir",
  "m_charLeast": "mínimo {n} caracteres",
  "m_register": "Registrar",
  "m_forgot": "Esqueceu",
  "m_allmostDone": "Quase pronto",
  "m_here": "Aqui",
  "m_filter": "Filtro | Filtros",
  "m_change": "Mudanças",
  "m_analyze": "Analisar",
  "m_submitReview": "Enviar para análise",
  "m_saveToLibrary": "Salvar na biblioteca",
  "m_delete": "Excluir",
  "m_approve": "Aprovar",
  "m_requirements": "Requisitos",
  "m_empty": "Vazio",
  "m_emptyRound": "Round vazio",
  "m_lockRound": "Travar este round para mim",
  "m_unlockRound": "Destravar este round",
  "m_findBest": "Listar os melhores",
  "m_other": "Outro",
  "m_win": "ganha",
  "m_lose": "perde",
  "m_draw": "empata",
  "m_notime": "s/ tempo",
  "m_select": "selecione",
  "m_resetSolutions": "Resetar soluções somente",
  "m_resetRound": "Resetar round",
  "m_includeDownvote": "Incluir tempos reportados?",
  "m_showDataFromPast": "Exibir dados de versões anteriores",
  "m_showOldTags": "Exibir tags descontinuadas",
  "m_challengeName": "Nome do desafio",
  "m_eventName": "Nome do evento",
  "m_numberRounds": "Número de rounds",
  "m_yes": "Sim",
  "m_no": "Não",
  "m_new": "Novo",
  "m_by": null,
  "m_checkOut": "Confira",
  "m_templateGuide": "Guia de Templates",
  "m_createNewCg": "Criar novo desafio",
  "m_createNewEvent": "Criar novo evento",
  "m_title": "Título",
  "m_twisty": "Curvas",
  "m_city": "Cidade",
  "m_rqLimit": "Limite do RQ",
  "m_points": "Pontos",
  "m_time": "Tempo",
  "m_timeToBeat": "Tempo a bater",
  "m_done": "Pronto",
  "m_sort": "Ordenar",
  "m_send": "Enviar",
  "m_backHome": "Voltar para início",
  "m_any": "Qualquer",
  "m_rarity": "Raridade",
  "m_car": "Carro | Carros",
  "m_changed": "Alterado",
  "m_noRecords": "Sem records",
  "m_showOtherTracks": "Mostrar outras",
  "m_showMore": "Mostrar mais",
  "m_lastContribution": "Últimas contribuições",
  "m_nothingFound": "Nada encontrado",
  "m_changedCars": "carros alterados",
  "m_uploadSuccess": "Upload com sucesso",
  "m_noData": "Sem dados a exibir",
  "m_noTimesFound": "Nenhum tempo encontrado",
  "m_logoutSuccess": "Logout com sucesso",
  "m_loginSuccess": "Você está logado",
  "m_deleteSuccess": "Excluído com sucesso",
  "m_saveSuccess": "Salvo com sucesso",
  "m_approveSuccess": "Aprovado com sucesso",
  "m_sentReview": "Enviar para análise",
  "m_language": "Idioma",
  "m_resetSavedHand": "Redefinir para a melhor solução",
  "m_showCustomTunes": "Mostrar tunagens personalizadas",
  "m_kingForceVertical": "Alterar display para vertical",
  "m_showBigCards": "Expandir carros",
  "m_chartHideOutOfFilter": "Esconder carros fora do filtro",
  "m_meta": "Meta",
  "m_race": "Corrida",
  "m_useTrackList": "Usar a lista de pistas",
  "m_modShowSuspectTimes": "Esconder tempos suspeitos",
  "m_newByCopy": "Novo via cópia",
  "m_eventShowOnlyPicks": "Somente selecionados",
  "m_eventForcePicks": "Forçar selecionados",
  "m_showPointsCgForce": "mostrar pontos",
  "m_statsView": "Mostrar detalhes",
  "m_multi": "Multi",
  "m_hidden": "Escondido",
  "m_enablePicks": "Habilitar escolhas",
  "m_clearPicks": "Limpas escolhas",
  "m_findRqSavers": "Procurar RQ savers",
  "m_homePointsToggle": "Mostrar botão de pontos",
  "m_zoom": "Zoom",
  "m_review": "Análise | Análises",
  "m_addReview": "Adicionar análise",
  "m_clubs": "Clubs",
  "m_showContributors": "Mostrar colaboradores",
  "m_tracksetName": "Nome da trackset",
  "m_tracksets": "Trackset",
  "m_reqs": "Reqs",
  "m_reqsName": "Nome dos requisitos",
  "m_daySelector": "Seletor de dia",
  "m_current": "atual",
  "m_saveDay": "Salvar dia",
  "m_createNewTrackset": "Criar nova trackset",
  "m_createNewReqs": "Criar novo requisito",
  "m_saveTrackset": "Salvar trackset",
  "m_saveReqs": "Salvar requisitos",
  "m_saveOriginalOrder": "Salvar como ordem original",
  "m_showOriginalOrder": "Mostrar ordem original",
  "m_filterPage": "Filtrar página",
  "m_notCurrent": "Não atual",
  "m_admin": "Admin",
  "m_carFinder": "Localizador de carros",
  "m_newPhotos": "Novas fotos",
  "m_testPoints": "Testar pontos",
  "m_downTimes": "Tempos reportados",
  "m_translationTool": "Ferramenta de tradução",
  "m_newCar": "Novo carro",
  "m_relativeBest": "Relativo ao melhor",
  "m_2xmultiplier": "Multiplicador 2x",
  "m_cheatSheet": "Cheatsheet",
  "m_simulateUntilGetAll": "Simular até sair todos",
  "m_simulateUntilGetOne": "Simular até sair um",
  "m_simulate": "Simular",
  "m_simulating": "Simulando",
  "m_impossibleRemoved": "{count} carros impossíveis removidos",
  "m_count": "Contagem",
  "m_quantityOfCars": "Quantidade de carros",
  "m_success": "Sucesso",
  "m_failed": "Falhou",
  "m_status": "Status",
  "m_cost": "Custo",
  "m_stop": "Parar",
  "m_probabilityPerOpen": "Probabilidade por abertura",
  "m_cumulativeProbability": "Probabilidade acumulada",
  "m_superLucky": "Super sorte",
  "m_veryLucky": "Muita sorte",
  "m_goodLuck": "Boa sorte",
  "m_littleLucky": "Pequena sorte",
  "m_badLuck": "Sorte ruim",
  "m_worstLuck": "Péssima sorte",
  "m_zeroLuck": "Zero sorte",
  "m_impossible": "Impossível",
  "m_customizePack": "Personalizar pack",
  "m_impossiblePack": "Esse pack não é possível",
  "m_impossibleCondition": "Essa condição não é possível",
  "m_permanents": "Permanentes",
  "m_linkDiscord": "Vincular sua conta discord",
  "m_sixDigitCode": "Código de 6 dígitos",
  "m_discordUserId": "Discord User ID",
  "m_discordLinkFinish": "Sua contas TDR e Discord agora estão vinculadas!",
  "m_submitYourCommunity": "Enviar sua comunidade",
  "m_wantToJoin": "Quer entrar?",
  "m_private": "Privado",
  "m_public": "Público",
  "m_official": "Oficial",
  "m_platform": "Plataforma",
  "m_selectPlatform": "Selecionar plataforma",
  "m_selectCountry": "Selecionar país",
  "m_userCount": "Contagem de usuários",
  "m_description": "Descrição",
  "m_optional": "Opcional",
  "m_informationForInvite": "Informação para convite",
  "m_link": "Link",
  "m_unlinkDiscord": "Desvincular meu discord",
  "m_inviter": "Anfitrião",
  "m_requestSent": "Pedido enviado",
  "p_userSentCgForAnalyse": "Obrigado! Seu round será analisado. Vem pro Discord se quiser discutir sobre.",
  "p_emptyRoundForUser": "Este round não está pronto ainda. Você pode ajudar criando e enviando para análise.",
  "p_emptyRound2": "Este round não está pronto ainda. Se você está nesse round, você pode ajudar a incluí-lo.",
  "p_emptyRoundLogin": "Logar para incluir este round ou fale com um moderador no nosso Discord:",
  "p_lockRoundPhrase": "Trave este round para poder incluí-lo",
  "p_modDoingRound": "{mod} está incluindo este round",
  "p_patronsOnly": "Esta funcionalidade está disponível somente para patrões (Tier {tier}).",
  "p_bestOfDescription": "Retorna uma lista dos melhores carros desta pista de acordo com o filtro. Um exemplo:",
  "p_chartsDescription": "Traz todo o banco e dados para o gráfico. Demonstração:",
  "p_templateWarning": "Certifique-se que o template é útil para a comunidade or ele não será aprovado.",
  "p_about1": "Este projeto foi desenvolvido por TiagoXavi and não tem relações com a Hutch Games Ltd.",
  "p_about2": "Qualquer problema ou sugestão, chega aí no Discord ou me mande um email (mighty.boy@topdrivesrecords.com).",
  "p_about3": "TDR é acessível a todos. Você pode apoiar o projeto para mantê-lo online e recebendo novas funcionalidades!",
  "p_resetPassHelper": "Digite seu email para receber um link para redefinir sua senha",
  "p_resetPassIfExist": "Se existe um usuário com este email, um link para redefinir senha foi enviado.",
  "p_confirmationWillSent": "E-mail de confirmação foi enviado",
  "p_checkYourEmailBox": "Por favor, verifique sua caixa de entrada",
  "p_spamToo": "Caixa de spam também!",
  "p_somethingWrong": "Algo errado não está certo",
  "p_beforeLeave": "Tem certeza que deseja sair?",
  "p_eventsKingDescription": "Você só pode ver 1 carro por raridade. Veja a lista completa em funcionamento:",
  "p_eventsKingLogin": "Faça login para ver o melhor carro para cada raridade",
  "p_eventHiddenForPatreons": "Esse evento é uma pré-visualização disponível para patreons pelo menos tier 3",
  "p_linkDiscordExplanationCommu": "Para enviar sua comunidade, você precisa:",
  "p_linkDiscordExplanationP1": "Para vincular sua conta, você precisa:",
  "p_linkDiscordExplanationP2": "• Uma conta Discord • Estar no servidor TDR do Discord • Ter o seu Discord User ID (use o comando /user no servidor TDR do Discord)",
  "p_linkDiscordCodeSent": "Um código foi enviado a você como mensagem privada.",
  "p_informationForInvite": "Que informação as pessoas precisam enviar para entrar na sua comunidade, como e-mail, telefone, usuário... Isso será exibido para aqueles que quiserem entrar na sua comunidade",
  "p_inviteYourDiscord": "Você receberá pedidos de entrada no seu discord, fique de olho",
  "p_privateDescript": "Pessoas vão pedir para entrar \n(Você precisa de uma conta discord)",
  "p_publicDescript": "Há um link público e qualquer um pode entrar",
  "p_userSentCommunityForAnalyse": "Obrigado! Sua comunidade será analisada pelos moderadores TDR.",
  "p_communityToBeInvited": "Para ser convidado, digite o que está sendo pedido abaixo:",
  "g_joinDiscord": "Chega aí no nosso Discord para dicas de template ou se quiser perguntar porque seu template não foi aceito.",
  "g_rule0": "Seu template deve ter um contexto, lista de carros que fazem sentido. Por exemplo mesma tag, mesmo país, mesmo pneu, mesma marca, mesma raridade... Ou algum contexto que a Hutch faz no jogo como eventos e desafios.",
  "g_rule1": "Você deve colocar todos os carros disponíveis para o contexto, ou pelo menos o que der. Não coloque somente carros que você tem.",
  "g_rule2": "O template deve comparar carros com características similares. Não faz sentido comparar RWD-Desempenho com 4WD-Todas em pistas de asfalto.",
  "g_rule3": "Não misture pistas off-road com pistas em asfalto se não tiver um motivo para isso.",
  "g_rule4": "Não precisa colocar todas as pistas do jogo. Isso deixa o site lento.",
  "g_rule5": "Tente ordenar as pistas por tipo, primeiro os drags, depois as com curvas, e primeiro asfalto, depois off-road.",
  "g_rule6": "Tente ordenar os carros por RQ (maiores primeiro) ou por performance.",
  "g_rule7": "O template não precisa ter todos os tempos cadastrados, mas você pode cadastrar o que conseguir antes de enviar.",
  "g_rule8": "Use títulos descritivos.",
  "g_rule9": "Dê uma olhada se não existe um template com a mesma proposta que a sua.",
  "s_dry": "Seco",
  "s_aspht": "Asfto",
  "s_wet": "Chuva",
  "s_dirt": "Terra",
  "s_gravel": "Csclho",
  "s_ice": "Gelo",
  "s_sand": "Areia",
  "s_snow": "Neve",
  "s_grass": "Grama",
  "c_topSpeed": "Vel. Máx.",
  "c_handling": "Direção",
  "c_hand": "@:c_handling | @:c_handling",
  "c_class": "Raridade | Raridades",
  "c_year": "Ano | Anos",
  "c_tyre": "Pneu | Pneus",
  "c_drive": "Tração | Trações",
  "c_clearance": "Altura | Alturas",
  "c_country": "País | Países",
  "c_brand": "Marca | Marcas",
  "c_tag": "Tag | Tags",
  "c_stock": "Fábrica",
  "c_weight": "Peso",
  "c_fuel": "Combustível",
  "c_seats": "Assentos",
  "c_enginePos": "Pos. motor",
  "c_bodyStyle": "Estilo",
  "c_prizeCar": "Carro prize",
  "c_prize cars": "Carros prize",
  "c_non-prize cars": "Carros não-prize",
  "c_low": "Baixo",
  "c_mid": "Médio",
  "c_high": "Alto",
  "c_performance": "Desempenho",
  "c_standard": "Padrão",
  "c_all-surface": "Todas",
  "c_off-road": "Off-road",
  "c_slick": "Liso",
  "c_saloon": "Sedan",
  "c_hatchback": "Hatch",
  "c_convertible": "Conversível",
  "c_estate": "Perua",
  "c_mpv": "Minivan",
  "c_petrol": "Gasolina",
  "c_electric": "Elétrico",
  "c_hybrid": "Híbrido",
  "c_bioethanol": "Bioetanol",
  "c_hydrogen": "Hidrogênio",
  "c_misc": "Outro",
  "c_midEngine": "Meio",
  "c_mid-rearEngine": "Retag.",
  "c_mixedEngine": "Misto",
  "c_rearEngine": "Traseiro",
  "c_frontEngine": "Frente",
  "c_tune": "Tunagem",
  "t_mile1": "Corrida de 1 Milha",
  "t_mile1r": "Corrida de 1 Milha (R)",
  "t_fast": "Circuito Rápido",
  "t_mile2": "Corrida de 1/2 Milha",
  "t_mile4": "Corrida de 1/4 Milha",
  "t_mile4r": "Corrida de 1/4 Milha (R)",
  "t_slalom": "Teste de Estabilidade",
  "t_csMed": "Ruas Médias da Cidade",
  "t_csSmall": "Ruas Pequenas da Cidade",
  "t_hClimb": "Subida do Morro",
  "t_hClimbr": "Subida do Morro (R)",
  "t_hairpin": "Estrada do Gancho",
  "t_kart": "Circuito de Kart",
  "t_mtTwisty": "Estrada Sinuosa na Montanha",
  "t_tCircuit": "Circuito Sinuoso",
  "t_tCircuitr": "Circuito Sinuoso (R)",
  "t_airplaneHangars": "Hangares de Avião",
  "t_runwayDragSlalom": "Corrida & Slalom na Pista de Pouso",
  "t_airplaneSlalom": "Slalom do Avião",
  "t_figureEight": "Formato Oito",
  "t_oceanCity": "Ruas da Cidade Costeira",
  "t_oceanSlalom": "Slalom Praieiro",
  "t_oceanLongDrag": "Corrida Longa Costeira",
  "t_canyonTour": "Turismo no Cânion",
  "t_tRoad": "Estrada Sinuosa",
  "t_carPark": "Estacionamento",
  "t_indoorKart": "Kart em Interior",
  "t_draglshape": "Corrida em L",
  "t_mnCity": "Ruas de Mônaco",
  "t_mnGforce": "Teste de Força G em Mônaco",
  "t_mtHairpin": "Grampo da Montanha",
  "t_rallyMed": "Circuito de Rally Médio",
  "t_rallySmall": "Circuito de Rally Pequeno",
  "t_mnCityNarrow": "Ruas Estreitas de Mônaco",
  "t_tokyoOverpass": "Ultrapassagem em Tóquio",
  "t_mnHairpin": "Grampo de Mônaco",
  "t_mnCityLong": "Ruas de Mônaco Longas",
  "t_tokyoOffRamp": "Rampa de Saída de Tóquio",
  "t_moto": "Pista de Motocross",
  "t_testBowl": "Pista de Testes",
  "t_testBowlr": "Pista de Testes (R)",
  "t_gForce": "Teste de Força G",
  "t_gForcer": "Teste de Força G (R)",
  "t_oceanShortDrag": "Corrida Curta Costeira",
  "t_mtSlalom": "Slalom na Montanha",
  "t_mtTour": "Tour da Montanha",
  "t_waterDrag": "Corrida na Orla",
  "t_tokyoBridge": "Ponte de Tóquio",
  "t_tokyoDrag": "Corrida de Tóquio",
  "t_tokyoGforce": "Teste de Força G em Tóquio",
  "t_tokyoLoop": "Circuito de Tóquio",
  "t_slalomr": "Teste de Estabilidade (R)",
  "t_speedbump12km": "Lombada 1/2 km",
  "t_speedbump1km": "Lombada 1 km",
  "t_serviceRoads": "Ruas de Acesso",
  "t_runwayLoop": "Volta na Pista de Pouso",
  "t_runwayDrag": "Corrida na Pista de Pouso",
  "t_oceanParking": "Estacionamento Costeiro",
  "t_oceanHighway": "Estrada Costeira",
  "t_forestRiver": "Travessia do Rio na Floresta",
  "t_mtIncline": "Estrada Inclinada na Montanha",
  "t_mtHill": "Subida na Montanha",
  "t_forestSlalom": "Slalom na Floresta",
  "t_forest": "Estrada na Floresta",
  "t_frozenLake": "Lago Congelado",
  "t_fastr": "Circuito Rápido (R)",
  "m_printBy": null,
  "m_display": null,
  "m_email": null,
  "m_round": null,
  "m_drag": null,
  "m_uploadPrint": null,
  "m_logout": null,
  "c_roadster": null,
  "c_coupe": null,
  "c_suv": null,
  "c_pickup": null,
  "c_van": null,
  "c_dragster": null,
  "c_twister": null,
  "c_speedster": null,
  "c_all-rounder": null,
  "c_diesel": null,
  "t_drag100b": null,
  "t_drag100": null,
  "t_drag120": null,
  "t_drag150b": null,
  "t_drag150": null,
  "t_drag170": null,
  "t_drag200": null,
  "t_drag60b": null,
  "t_drag60": null,
  "t_drag100150": null,
  "t_drag30130": null,
  "t_drag50150": null,
  "t_drag75125": null,
  "t_smallRoad": "Estrada Escondida",
  "t_townSlalom": "Slalom na Cidade",
  "t_townSprint": "Sprint na Cidade",
  "t_townTour": "Tour na Cidade",
  "t_cross": "Cross-Country",
  "t_fieldSlalom": "Slalom na Planície",
  "t_frozenRivSla": null,
  "t_northloop": null,
  "t_northloop1": null,
  "t_northloop2": null,
  "t_northloop3": null,
  "t_northloop4": null,
  "t_northloop5": null,
  "t_riverSprint": null,
  "t_butte": "Colina",
  "t_canyonLookout": "Mirante no Cânion",
  "t_canyonDtRoad": "Estrada de Terra no Cânion",
  "t_canyonButte": "Colina no Cânion",
  "t_lookout": "Mirante",
  "t_dtRoad": "Estrada de Terra",
  "t_lumberTwisty": "Serraria Circuito Sinuoso",
  "t_lumberForest": "Serraria Floresta Rally",
  "t_lumberRiver": "Serraria Rio Rally",
  "m_vNewPhotos": "{version} Novas Fotos",
  "p_emptyRoundVoteForUser": "Tudo certo com esse envio?",
  "c_brakeClass": "Classe de freio",
  "c_brake": "Freio",
  "c_name": "Nome",
  "t_nwCircuit": "Circuito de Nova York",
  "t_nwDrag": "Arrancada de Nova York",
  "t_nwGforce": "Força G de Nova York",
  "t_nwLoop": "Circuito da Times Square de Nova York",
  "t_nwCathedral": "Catedral de Nova York",
  "t_nwTour": "Excursão ao Central Park em Nova York",
  "t_dockAltCirc": "Circuito Alternativo das Docas",
  "t_dockUdrag": "Arrasto de Curva em U nas Docas",
  "t_dockGforceDrag": "Arrasto da Força G nas Docas",
  "t_dockKart": "Kart nas Docas",
  "t_dockCity": "Ruas da Cidade nas Docas",
  "t_dockCirc": "Circuito das Docas",
  "m_multiTags": null,
  "m_edit": null,
  "t_laguna": null,
  "t_desertHill": null,
  "t_desertSmall": null,
  "t_desertBig": null,
  "t_desertRallyDirt": null,
  "t_drag62": null,
  "t_drag124": null,
  "t_dealsGap1": null,
  "t_dealsGap2": null,
  "t_dealsGap3": null,
  "t_dealsGap4": null,
  "t_dealsGap5": null,
  "t_dealsGap": null,
  "t_dealsGapBack": null,
  "t_nwSlalom": null
}