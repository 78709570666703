export default {
  "language": "Español(España)",
  "m_campaign": "Campaña",
  "m_home": "Inicio",
  "m_compare": "Comparar",
  "m_challenges": "Desafíos",
  "m_event": "Evento",
  "m_events": "Eventos",
  "m_charts": "Tablas",
  "m_options": "Opciones",
  "m_about": "Sobre",
  "m_vChanges": "Cambios {version}",
  "m_library": "Librería",
  "m_addLibrary": "Añadir a la librería",
  "m_sharableLink": "Enlace para compartir",
  "m_copy": "Copiar",
  "m_listCars": "Listado de autos",
  "m_type": "Tipo",
  "m_search": "Buscar",
  "m_searchCar": "Buscar auto",
  "m_searchTracks": "Buscar circuito",
  "m_selectTrack": "Seleccionar circuito",
  "m_contributors": "Contribuyentes",
  "m_printBy": "captura hecha por",
  "m_display": "Vista",
  "m_colors": "Colores",
  "m_more3dot": "Más...",
  "m_trackset": "Conjunto de circuitos",
  "m_clear": "Eliminar",
  "m_clearCars": "Eliminar autos",
  "m_load": "Cargar",
  "m_loadChart": "Cargar tabla",
  "m_memory": "Memoria",
  "m_image": "Imagen",
  "m_pleaseWait3dot": "Por favor, espere...",
  "m_downloadPng": "Descargar PNG",
  "m_save": "Guardar",
  "m_cancel": "Cancelar",
  "m_login": "Iniciar sesión",
  "m_gotoLogin": "Ir a inicio de sesión",
  "m_email": "Correo electrónico",
  "m_emailVerification": "Verificación del correo electrónico",
  "m_username": "Usuario",
  "m_password": "Contraseña",
  "m_newPassword": "Nueva contraseña",
  "m_repeatPassword": "Repite la contraseña",
  "m_repeat": "Repetir",
  "m_charLeast": "al menos {n} caracteres",
  "m_register": "Registrar",
  "m_allmostDone": "Casi terminado",
  "m_here": "Aquí",
  "m_filter": "Filtro | Filtros",
  "m_change": "Cambiar",
  "m_analyze": "Analizar",
  "m_submitReview": "Publicar para revisión",
  "m_saveToLibrary": "Guardar en la biblioteca",
  "m_delete": "Borrar",
  "m_approve": "Aprobar",
  "m_round": "Ronda | Rondas",
  "m_requirements": "Requisitos",
  "m_empty": "Vacío",
  "m_emptyRound": "Ronda vacía",
  "m_lockRound": "Bloquear ronda para mi",
  "m_forgot": "Recuperar contraseña",
  "m_unlockRound": "Desbloquear esta ronda",
  "m_findBest": "Encontrar el mejor",
  "m_other": "Otro",
  "m_win": "gana",
  "m_lose": "pierde",
  "m_draw": "empate",
  "m_notime": "sin tiempo",
  "m_select": "seleccionar",
  "m_resetSolutions": "Reiniciar solo soluciones",
  "m_resetRound": "Reiniciar ronda",
  "m_includeDownvote": "¿incluir tiempos con votos negativos?",
  "m_showDataFromPast": "Mostrar datos de versiones anteriores",
  "m_showOldTags": "Mostrar etiquetas obsoletas",
  "m_challengeName": "Nombre del desafío",
  "m_eventName": "Nombre del evento",
  "m_numberRounds": "Número de rondas",
  "m_yes": "Sí",
  "m_no": "No",
  "m_new": "Nuevo",
  "m_by": "por",
  "m_checkOut": "Verificar",
  "m_templateGuide": "Guia de plantilla",
  "m_createNewCg": "Crear un nuevo desafío",
  "m_createNewEvent": "Crear un nuevo evento",
  "m_title": "Título",
  "m_twisty": "Sinuoso",
  "m_drag": "Arrancón",
  "m_city": "Ciudad",
  "m_rqLimit": "Limite de RQ",
  "m_points": "Puntos",
  "m_time": "Tiempo",
  "m_timeToBeat": "Tiempo a batir",
  "m_uploadPrint": "Publicar captura",
  "m_done": "Hecho",
  "m_sort": "Ordenar",
  "m_send": "Enviar",
  "m_backHome": "Volver al inicio",
  "m_any": "Cualquiera",
  "m_rarity": "Rareza",
  "m_car": "Auto | Autos",
  "m_changed": "Actualizado",
  "m_noRecords": "Sin tiempos",
  "m_showOtherTracks": "Mostrar otras pistas",
  "m_showMore": "Mostrar más",
  "m_lastContribution": "Últimos contribuyentes",
  "m_nothingFound": "No encontrado",
  "m_changedCars": "autos actualizados",
  "m_uploadSuccess": "Piblicación completada con éxito",
  "m_noData": "Sin datos que mostrar",
  "m_noTimesFound": "No se han encontrado tiempos",
  "m_logoutSuccess": "Sesión cerrada",
  "m_loginSuccess": "Has iniciado sesión",
  "m_deleteSuccess": "Borrado con éxito",
  "m_saveSuccess": "Guardado con éxito",
  "m_approveSuccess": "Aprobado con éxito",
  "m_sentReview": "Publicado para evaluación",
  "m_language": "Idioma",
  "m_logout": "Desconectarse",
  "m_resetSavedHand": "Reiniciar a la mejor solución",
  "p_userSentCgForAnalyse": "¡Gracias! Tu ronda será analizada. Únete a nuestro Discord para hablar si lo deseas.",
  "p_emptyRoundForUser": "Esta ronda todavía no está actualizada. Puedes ayudar creándola y después, subiéndola para su revisión.",
  "p_emptyRound2": "Esta ronda aún no ha sido completada. Si estás en esta ronda, puedes ayudar a rellenarla.",
  "p_emptyRoundLogin": "Inicia sesión para crear esta ronda o contacta con un moderador en el servidor de Discord.",
  "p_lockRoundPhrase": "Bloqueale a los demás el acceso a esta ronda para rellenarla tú.",
  "p_modDoingRound": "{mod} está haciendo esta ronda.",
  "p_patronsOnly": "Esta característica solo está disponible para suscriptores de Patreon de (Tier{tier}).",
  "p_bestOfDescription": "Devuelve una lista con los mejores coches para el circuito y filtros seleccionados. Por ejemplo:",
  "p_chartsDescription": "Pone todos los datos de un circuito en un gráfico. Como demostración:",
  "p_templateWarning": "Asegúrate de que tu plantilla es de utilidad para la comunidad, de lo contrario no será aprobada.",
  "p_about1": "Este proyecto fue realizado por TiagoXavi y no tiene relación con Hutch Games Ltd.",
  "p_about2": "Si tienes un problema o una sugerencia, por favor únete al servidor de Discord o manda un correo a la siguiente dirección (mighty.boy@topdrivesrecords.com).",
  "p_about3": "TDR es de uso gratuito para todos. Puedes apoyar el proyecto para mantenerlo operativo y para recibir nuevas funciones.",
  "p_resetPassHelper": "Escribe tu dirección de correo eletrónico para recibir un correo para restablecer tu contraseña.",
  "p_resetPassIfExist": "Si existe una cuenta con este correo, se te enviará un link para restablecer la contraseña.",
  "p_confirmationWillSent": "Correo de confirmación enviado.",
  "p_checkYourEmailBox": "Por favor, revisa tu bandeja de correo.",
  "p_spamToo": "Revisa también la carpeta de spam",
  "p_somethingWrong": "Algo no ha funcionado",
  "g_joinDiscord": "Únete al servidor de Discord de TDR para hablar sobre mejoras en tu plantilla o discutir por qué tu plantilla ha sido rechazada.",
  "g_rule0": "Tu plantilla debe contener contexto que tenga sentido para los coches.\nComo por ejemplo misma etiqueta, mismo país, mismos neumáticos, misma marca, misma clase... O algún contexto como hace Hutch como por ejemplo los requisitos de los eventos/desafíos.",
  "g_rule1": "Debes incluir todos los coches disponibles según el contexto o al menos intentar incluirlos todos.\nNo añadas solo los coches que posees.",
  "g_rule2": "La plantilla debe comparar coches con estadísticas similares. No tiene sentido comparar un coche de tracción RWD y neumáticos rendimiento con uno 4WD y neumáticos todo tiempo en un circuito de asfalto seco.",
  "g_rule3": "Mezcla circuitos de carretera con todoterreno solo si hay un motivo para hacerlo.",
  "g_rule4": "No necesitas añadir todos los circuitos en el juego. La aplicación tendrá problemas de rendimiento si lo haces.",
  "g_rule5": "Intenta ordenar los circuitos por tipo como por ejemplo circuitos de aceleración con la misma superficie.",
  "g_rule6": "Intenta ordenar los coches por RQ (mayor a menor) o por su desempeño en los circuitos seleccionados.",
  "g_rule7": "La plantilla no tiene que tener todos los tiempos rellenados, pero deberías rellenar los que puedas antes de enviarla.",
  "g_rule8": "Usa un título descriptivo.",
  "g_rule9": "Revisa si ya existe una plantilla con el mismo motivo que el tuyo.",
  "s_dry": "Seco",
  "s_aspht": "Asfal",
  "s_wet": "Mojad",
  "s_dirt": "Tierr",
  "s_gravel": "Grava",
  "s_ice": "Hielo",
  "s_sand": "Arena",
  "s_snow": "Nieve",
  "s_grass": "Cespe",
  "c_topSpeed": "Velocidad máxima",
  "c_handling": "Maniobrabilidad",
  "c_hand": "@:c_handling | @:c_handling",
  "c_class": "Clase | Clases",
  "c_year": "Año | Años",
  "c_tyre": "Neumático | Neumáticos",
  "c_drive": "Tracción | Tracciones",
  "c_clearance": "Distancia del suelo | Distancias al suelo",
  "c_country": "País |Países",
  "c_brand": "Marca | Marcas",
  "c_tag": "Etiqueta | Etiquetas",
  "c_stock": "De serie",
  "c_weight": "Peso",
  "c_fuel": "Combustible",
  "c_seats": "Asientos",
  "c_enginePos": "Posición del motor",
  "c_bodyStyle": "Estilo de carrocería",
  "c_prizeCar": "Auto recompensa",
  "c_prize cars": "Autos recompensa",
  "c_non-prize cars": "Autos no-recompensa",
  "c_mid": "Media",
  "c_low": "Baja",
  "c_high": "Alta",
  "c_performance": "Rendimiento",
  "c_standard": "Basico",
  "c_all-surface": "Todo tiempo",
  "c_off-road": "Todoterreno",
  "c_slick": "Lisos",
  "c_saloon": "Sedán",
  "c_roadster": "Deportivo",
  "c_coupe": "Coupé",
  "c_hatchback": "Hatchback",
  "c_suv": "SUV",
  "c_convertible": "Convertible",
  "c_estate": "Familiar",
  "c_pickup": "Camioneta",
  "c_mpv": "Monovolumen",
  "c_van": "Furgoneta",
  "c_dragster": "Dragster",
  "c_twister": "Agil",
  "c_all-rounder": "Bueno en todo",
  "c_petrol": "Gasolina",
  "c_diesel": "Diesel",
  "c_electric": "Eléctrico",
  "c_hybrid": "Híbrido",
  "c_bioethanol": "Bio-etanol",
  "c_hydrogen": "Hidrógeno",
  "c_misc": "Miscélaneo",
  "c_frontEngine": "Frente",
  "c_midEngine": "Central",
  "c_mid-rearEngine": "Central-trasero",
  "c_mixedEngine": "Combinado",
  "c_rearEngine": "Trasero",
  "c_tune": "Tuneo",
  "t_drag100b": "0-100-0mph",
  "t_drag100": "0-100mph",
  "t_drag120": "0-120mph",
  "t_drag150b": "0-150-0mph",
  "t_drag150": "0-150mph",
  "t_drag170": "0-170mph",
  "t_drag200": "0-200mph",
  "t_drag60b": "0-60-0mph",
  "t_drag60": "0-60mph",
  "t_mile1": "Arrancón 1 milla",
  "t_mile1r": "Arrancón 1 milla (R)",
  "t_mile2": "Arrancón 1/2 milla",
  "t_mile4": "Arrancón 1/4 milla",
  "t_mile4r": "Arrancón 1/4 milla (R)",
  "t_drag100150": "100-150mph (R)",
  "t_drag30130": "30-130mph (R)",
  "t_drag50150": "50-150mph (R)",
  "t_airplaneHangars": "Hangares de aviones",
  "t_airplaneSlalom": "Eslalon aeronáutico",
  "t_drag75125": "75-125mph (R)",
  "t_canyonTour": "Paseo por el cañon",
  "t_carPark": "Estacionamiento",
  "t_townSlalom": "Eslalon de ciudad",
  "t_csMed": "Calles de la ciudad mediano",
  "t_csSmall": "Calles de la ciudad chico",
  "t_fast": "Circuito rápido",
  "t_fastr": "Circuito rápido (R)",
  "t_figureEight": "Ocho",
  "t_forest": "Carretera forestal",
  "t_forestSlalom": "Eslalon forestal",
  "t_frozenLake": "Lago de hielo",
  "t_gForce": "Prueba de fuerza G",
  "t_gForcer": "Prueba de fuerza G (R)",
  "t_hairpin": "Horquilla",
  "t_hClimb": "Montaña arriba",
  "t_hClimbr": "Montaña arriba (R)",
  "t_indoorKart": "Karts bajo techo",
  "t_kart": "Circuito de karts",
  "t_mnGforce": "Prueba de fuerza G de Mónaco",
  "t_draglshape": "Picódromo en L",
  "t_mnCity": "Calles de Mónaco",
  "t_mnHairpin": "Horquilla de Mónaco",
  "t_mnCityLong": "Calles de Mónaco larga",
  "t_mnCityNarrow": "Callejones de Mónaco",
  "t_moto": "Pista de motocross",
  "t_mtHairpin": "Horquilla de la montaña",
  "t_northloop": "North Loop",
  "t_northloop1": "North Loop 1",
  "t_northloop2": "North Loop 2",
  "t_northloop3": "North Loop 3",
  "t_northloop4": "North Loop 4",
  "t_northloop5": "North Loop 5",
  "t_oceanSlalom": "Eslalon de playa junto al mar",
  "t_rallyMed": "Circuito mediano de rallycross",
  "t_rallySmall": "Circuito chico de rallycross",
  "t_runwayDrag": "Picódromo aeroportuario",
  "t_slalom": "Test de eslalon",
  "t_slalomr": "Test de eslalon (R)",
  "t_speedbump12km": "Badén 1/2km",
  "t_speedbump1km": "Badén 1km",
  "t_testBowl": "Anillo de pruebas",
  "t_testBowlr": "Anillo de pruebas (R)",
  "t_tokyoLoop": "Bucle de Tokio",
  "t_tokyoOffRamp": "Rampa de salida de Tokio",
  "t_tokyoOverpass": "Pasarela de Tokio",
  "t_tCircuit": "Circuito sinuoso",
  "t_tCircuitr": "Circuito sinuoso (R)",
  "t_tRoad": "Camino sinuoso",
  "t_waterDrag": "Arrancones del litoral",
  "t_smallRoad": "Carretera trasera",
  "t_townSprint": "City Sprint",
  "t_townTour": "City Tour",
  "t_cross": "Campo a través",
  "t_fieldSlalom": "Field Slalom",
  "t_forestRiver": "Cruce del río del bosque ",
  "t_frozenRivSla": "Cruce del río helado",
  "t_mtHill": "Montaña arriba",
  "t_mtIncline": "Camino con peralte de la montaña",
  "t_mtSlalom": "Eslalon de la montaña",
  "t_mtTour": "Paseo por la montaña",
  "t_mtTwisty": "Camino sinuoso de la montaña",
  "t_oceanCity": "Calles de ciudad junto al mar",
  "t_oceanHighway": "Autopista junto al mar",
  "t_oceanLongDrag": "Picodromo largo junto al mar",
  "t_oceanParking": "Aparcamiento junto al mar",
  "t_oceanShortDrag": "Picodromo corto junto al mar",
  "t_riverSprint": "Sprint del río",
  "t_runwayDragSlalom": "Picódromo y eslalon aeroportuarios",
  "t_runwayLoop": "Bucle aeroportuario",
  "t_serviceRoads": "Vias de servicio",
  "t_tokyoBridge": "Puente de tokio",
  "t_tokyoDrag": "Arrancones de tokio",
  "t_tokyoGforce": "Prueba de fuerza G de Tokio",
  "m_showCustomTunes": "Mostrar tuneos personalizados",
  "t_butte": "Loma",
  "t_canyonLookout": "Mirador del cañon",
  "t_canyonDtRoad": "Camino de tierra del cañon",
  "t_canyonButte": "Loma del cañon",
  "t_lookout": "Mirador",
  "t_dtRoad": "Camino de tierra",
  "m_vNewPhotos": "Nuevas Fotos {version}",
  "m_impossiblePack": "Este pack no es posible",
  "m_impossible": "Imposible",
  "m_permanents": "Permanentes",
  "p_emptyRoundVoteForUser": "Todos los datos están correctos?",
  "c_name": "Nombre",
  "t_laguna": "Laguna Seca",
  "t_lumberTwisty": "Circuito Sinuoso del Aserradero",
  "t_dockCity": "Calles de Ciudad del Puerto",
  "t_drag62": "0-62",
  "t_drag124": "0-124",
  "m_kingForceVertical": null,
  "m_showBigCards": null,
  "m_chartHideOutOfFilter": null,
  "m_meta": null,
  "m_race": null,
  "m_useTrackList": null,
  "m_modShowSuspectTimes": null,
  "m_newByCopy": null,
  "m_eventShowOnlyPicks": null,
  "m_eventForcePicks": null,
  "m_showPointsCgForce": null,
  "m_statsView": null,
  "m_multi": null,
  "m_multiTags": null,
  "m_hidden": null,
  "m_enablePicks": null,
  "m_clearPicks": null,
  "m_findRqSavers": null,
  "m_homePointsToggle": null,
  "m_zoom": null,
  "m_review": null,
  "m_addReview": null,
  "m_clubs": null,
  "m_showContributors": null,
  "m_tracksetName": null,
  "m_tracksets": null,
  "m_reqs": null,
  "m_reqsName": null,
  "m_daySelector": null,
  "m_current": null,
  "m_saveDay": null,
  "m_createNewTrackset": null,
  "m_createNewReqs": null,
  "m_saveTrackset": null,
  "m_saveReqs": null,
  "m_saveOriginalOrder": null,
  "m_showOriginalOrder": null,
  "m_filterPage": null,
  "m_notCurrent": null,
  "m_admin": null,
  "m_carFinder": null,
  "m_newPhotos": null,
  "m_testPoints": null,
  "m_downTimes": null,
  "m_translationTool": null,
  "m_newCar": null,
  "m_relativeBest": null,
  "m_2xmultiplier": null,
  "m_cheatSheet": null,
  "m_simulateUntilGetAll": null,
  "m_simulateUntilGetOne": null,
  "m_simulate": null,
  "m_simulating": null,
  "m_impossibleRemoved": null,
  "m_count": null,
  "m_quantityOfCars": null,
  "m_success": null,
  "m_failed": null,
  "m_status": null,
  "m_cost": null,
  "m_stop": null,
  "m_openLimit": null,
  "m_probabilityPerOpen": null,
  "m_cumulativeProbability": null,
  "m_superLucky": null,
  "m_veryLucky": null,
  "m_goodLuck": null,
  "m_littleLucky": null,
  "m_badLuck": null,
  "m_worstLuck": null,
  "m_zeroLuck": null,
  "m_customizePack": null,
  "p_beforeLeave": null,
  "p_eventsKingDescription": null,
  "p_eventsKingLogin": null,
  "p_eventHiddenForPatreons": null,
  "c_speedster": null,
  "t_desertHill": null,
  "t_desertSmall": null,
  "t_desertBig": null,
  "t_desertRallyDirt": null,
  "t_dockAltCirc": null,
  "t_dockCirc": null,
  "t_dockGforceDrag": null,
  "t_dockKart": null,
  "t_dockUdrag": null,
  "t_lumberForest": null,
  "t_lumberRiver": null
}