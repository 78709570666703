export default {
  "language": "Slovak",
  "m_campaign": "Kampaň",
  "m_home": "Domov",
  "m_compare": "Porovnať",
  "m_challenges": "Challenges",
  "m_event": "Event",
  "m_events": "Events",
  "m_charts": "Grafy",
  "m_options": "Nastavenia",
  "m_vChanges": "{version} Zmeny",
  "m_library": "Knižnica",
  "m_addLibrary": "Pridať do Knižnice",
  "m_sharableLink": "Odkaz na zdieľanie",
  "m_copy": "Kopírovať",
  "m_listCars": "Zoznam vozidiel",
  "m_type": "Typ",
  "m_search": "Hľadať",
  "m_searchCar": "Hľadať vozidlo",
  "m_searchTracks": "Hľadať trať",
  "m_selectTrack": "Zvoliť trať",
  "m_contributors": "Prispievatelia",
  "m_printBy": "Odfotil",
  "m_display": "Zobraz",
  "m_colors": "Farby",
  "m_more3dot": "Viac...",
  "m_clear": "Vymazať",
  "m_trackset": "Trackset",
  "m_clearCars": "Vymazať vozidlá",
  "m_load": "Načítať",
  "m_loadChart": "Načítať graf",
  "m_memory": "Pamäť",
  "m_image": "Obrázok",
  "m_pleaseWait3dot": "Prosím počkajte...",
  "m_downloadPng": "Stiahnúť PNG",
  "m_save": "Uložiť",
  "m_cancel": "Zrušiť",
  "m_email": "Email",
  "m_emailVerification": "Verifikácia emailu",
  "m_login": "Prihlásiť",
  "m_gotoLogin": "Prejdi k prihláseniu",
  "m_username": "Meno používateľa",
  "m_password": "Heslo",
  "m_newPassword": "Nové heslo",
  "m_repeatPassword": "Zopakuj heslo",
  "m_repeat": "Zopakovať",
  "m_charLeast": "najmenej {n} znakov",
  "m_register": "Registrovať",
  "m_forgot": "Zabudnúť",
  "m_allmostDone": "Takmer hotovo",
  "m_here": "Tu",
  "m_filter": "Filter | Filtre",
  "m_change": "Zmeniť",
  "m_analyze": "analyzovať",
  "m_submitReview": "Zaslať na schválenie",
  "m_saveToLibrary": "Uložiť do knižnice",
  "m_delete": "Vymazať",
  "m_approve": "Schváliť",
  "m_round": "Kolo | Kolá",
  "m_requirements": "Požiadavky",
  "m_empty": "Prázdne",
  "m_unlockRound": "Odomknúť kolo",
  "m_emptyRound": "Vyprázdniť kolo",
  "m_lockRound": "Uzamknúť kolo pre mňa",
  "m_findBest": "Nájdi najlepšie",
  "m_other": "Ostatné",
  "m_win": "výhra",
  "m_lose": "prehra",
  "m_draw": "remíza",
  "m_notime": "nie je čas",
  "m_select": "zvoliť",
  "m_resetSolutions": "Resetnúť len riešenia",
  "m_resetRound": "Resetnúť kolo",
  "m_includeDownvote": "Zahrnúť nahlásené časy",
  "m_showDataFromPast": "Ukázať dáta zo starších verzií",
  "m_showOldTags": "Ukázať zastarané tagy",
  "m_challengeName": "Názov Challenge",
  "m_eventName": "Názov Eventu",
  "m_numberRounds": "Počet kôl",
  "m_yes": "Áno",
  "m_no": "Nie",
  "m_new": "Nové",
  "m_by": " od",
  "m_checkOut": "Vyskúšať",
  "m_templateGuide": "Pokyny pre šablóny",
  "m_createNewCg": "Vytvoriť nový challenge",
  "m_createNewEvent": "Vytvoriť nový event",
  "m_title": "Názov",
  "m_twisty": "Twisty",
  "m_drag": "Drag",
  "m_city": "Mesto",
  "m_rqLimit": "RQ limit",
  "m_points": "Body",
  "m_time": "Čas",
  "m_timeToBeat": "Čas na porazenie",
  "m_uploadPrint": "Nahrať obrázok",
  "m_done": "Ok",
  "m_sort": "Zoradiť",
  "m_send": "Poslať",
  "m_backHome": "Domov",
  "m_any": "Hocaké",
  "m_rarity": "Trieda",
  "m_car": "Vozidlo | Vozidlá",
  "m_changed": "Zmenené",
  "m_noRecords": "Bez záznamov",
  "m_showOtherTracks": "Ukázať ostatné trate",
  "m_showMore": "Ukázať viac",
  "m_lastContribution": "Posledný prispievatelia",
  "m_nothingFound": "Nenájdené",
  "m_changedCars": "zmenené vozidlá",
  "m_uploadSuccess": "Nahrávanie úspešné",
  "m_noData": "Žiadne dáta k zobrazeniu",
  "m_noTimesFound": "Žiadne časy neboli nájdené",
  "m_logoutSuccess": "Odhlásenie úspešné",
  "m_loginSuccess": "Prihlásil si sa",
  "m_deleteSuccess": "Úspešne vymazané",
  "m_saveSuccess": "Úspešné uložené",
  "m_approveSuccess": "Úspešne schválené",
  "m_sentReview": "Odoslané na schválenie",
  "m_language": "Jazyk",
  "p_userSentCgForAnalyse": "Ďakujeme! Tvoje kolo bude analyzované. Ak chceš o tom hovoriť, pripoj sa na Discord.",
  "p_emptyRoundForUser": "Toto kolo zatiaľ nebolo vytvorené. Môžeš pomôcť ho vytvoriť a potom ho odoslať na schválenie.",
  "p_emptyRound2": "Toto kolo zatiaľ nie je hotové. Ak si na tomto kole, môžeš nám s ním pomôcť.",
  "p_emptyRoundLogin": "Prihlás sa na začatie vytvárania tohto kola, alebo kontaktuj moderátorov na našom discord servery.",
  "p_lockRoundPhrase": "Uzamknúť kolo pre teba pre začatie prác",
  "p_modDoingRound": "{mod} pracuje na tomto kole",
  "p_patronsOnly": "Táto funkcia je povolená iba pre členov Patrenou (Tier {tier}).",
  "p_bestOfDescription": "Ukáže zoznam vozidiel najlepších vozidiel pre zvolenú trať a filter. Príklad:",
  "p_chartsDescription": "Umiestňuje celú databázu trasy do grafu. Príklad:",
  "m_about": "Info",
  "p_templateWarning": "Uisti sa že template je užitočný pre komunitu, v opačnom prípade nebude schválený.",
  "p_about1": "Tento projekt vytvoril TiagoXavi a nie je nijako spojený s Hutch Games Ltd.",
  "p_about2": "Akýkoľvek problém alebo návrh na zlepšenie nahlas na Discord servery  alebo odošli email (mighty.boy@topdrivesrecords.com).",
  "p_about3": "TDR je zadarmo pre všetkých. Projekt je možné podporiť na udržanie v chode a vytvárania nových funkcionalít",
  "p_resetPassHelper": "Napíš email na ktorý sa odošle odkaz pre resetovanie hesla.",
  "p_resetPassIfExist": "Ak používateľ s vloženou emailovou adresou existuje, odkaz na resetovanie hesla bol odoslaný.",
  "p_confirmationWillSent": "Potvrdzujúci email bude odoslaný.",
  "p_checkYourEmailBox": "Prosím, skontroluj svoju emailovú schránku.",
  "p_spamToo": "Spam tiež!",
  "p_somethingWrong": "Niečo sa pokazilo",
  "c_topSpeed": "Maximálna rýchlosť",
  "c_handling": "Ovládateľnosť",
  "c_hand": "@:c_handling | @:c_handling",
  "c_class": "Trieda | Triedy",
  "c_year": "Rok | Roky",
  "c_tyre": "Pneumatika | Pneumatiky",
  "c_drive": "Náhon | Náhony",
  "c_country": "Krajina | Krajiny",
  "c_clearance": "Svetlá výška | Svetlé výšky",
  "c_brand": "Značka | Značky",
  "c_tag": "Tag | Tagy",
  "c_stock": "Základ",
  "c_weight": "Váha",
  "c_fuel": "Palivo",
  "c_seats": "Sedadlá",
  "c_enginePos": "Pozícia motoru",
  "c_bodyStyle": "Štýl karosérie",
  "c_prizeCar": "Pohárové vozidlo",
  "c_prize cars": "Pohárové vozidlá",
  "c_non-prize cars": "Nepohárové vozidlá",
  "c_mid": "Stred",
  "c_low": "Nízka",
  "c_high": "Vysoká",
  "c_standard": "Štandard",
  "c_all-surface": "All-surface",
  "c_off-road": "Offroad",
  "c_performance": "Performance",
  "c_slick": "Slick",
  "c_saloon": "Sedan",
  "c_roadster": "Roadster",
  "c_coupe": "Coupe",
  "c_hatchback": "Hatchback",
  "c_suv": "SUV",
  "c_convertible": "Convertible",
  "c_estate": "Combi",
  "c_pickup": "Pickup",
  "c_mpv": "MPV",
  "c_van": "Van",
  "c_dragster": "Dragster",
  "c_petrol": "Benzín",
  "c_diesel": "Diesel",
  "c_electric": "Elektrické",
  "c_hybrid": "Hybrid",
  "c_bioethanol": "Bioethanol",
  "c_twister": "Twister",
  "c_all-rounder": "All-rounder",
  "c_hydrogen": "Vodík",
  "c_misc": "Rôzne",
  "c_frontEngine": "V predu",
  "c_midEngine": "V strede",
  "c_mid-rearEngine": "V strede- v zadu",
  "c_mixedEngine": "Rôzne",
  "c_rearEngine": "V zadu",
  "t_drag100b": "0-100-0mph",
  "t_drag100": "0-100mph",
  "t_drag120": "0-120mph",
  "t_drag150b": "0-150-0mph",
  "t_drag150": "0-150mph",
  "t_drag170": "0-170mph",
  "t_drag200": "0-200mph",
  "t_drag60b": "0-60-0mph",
  "t_drag60": "0-60mph",
  "t_mile1": "1 Mile",
  "t_mile1r": "1 Mile (R)",
  "t_mile2": "1/2 Mile",
  "t_mile4": "1/4 Mile",
  "t_mile4r": "1/4 Mile (R)",
  "t_drag100150": "100-150mph (R)",
  "t_drag30130": "30-130mph (R)",
  "t_drag50150": "50-150mph (R)",
  "t_drag75125": "75-125mph (R)",
  "t_airplaneHangars": "Airplane Hangars",
  "t_airplaneSlalom": "Airplane Slalom",
  "t_smallRoad": "Back Road",
  "t_canyonTour": "Canyon Tour",
  "t_carPark": "Car Park",
  "t_townSlalom": "City Slalom",
  "t_townSprint": "City Sprint",
  "t_csMed": "City Streets Medium",
  "t_csSmall": "City Streets Small",
  "t_townTour": "City Tour",
  "t_cross": "Cross Country",
  "t_fast": "Fast Circuit",
  "t_fastr": "Fast Circuit (R)",
  "t_fieldSlalom": "Field Slalom",
  "t_figureEight": "Figure Of Eight",
  "t_forestRiver": "Forest River",
  "t_forest": "Forest Road",
  "t_forestSlalom": "Forest Slalom",
  "t_frozenLake": "Frozen Lake",
  "t_frozenRivSla": "Frozen River Slalom",
  "t_gForce": "G-Force",
  "t_gForcer": "G-Force (R)",
  "t_hairpin": "Hairpin Road",
  "t_hClimb": "Hill Climb",
  "t_hClimbr": "Hill Climb (R)",
  "t_indoorKart": "Indoor Karting",
  "t_kart": "Karting Circuit",
  "t_draglshape": "L-Shape Drag",
  "t_mnGforce": "Monaco G-Force",
  "t_mnHairpin": "Monaco Hairpin",
  "t_mnCityNarrow": "Monaco Narrow Streets",
  "t_mnCity": "Monaco Streets",
  "t_mnCityLong": "Monaco Streets Long",
  "t_moto": "Motocross Track",
  "t_mtHairpin": "Mountain Hairpin",
  "t_mtHill": "Mountain Hill Climb",
  "t_mtIncline": "Mountain Incline Road",
  "t_mtSlalom": "Mountain Slalom",
  "t_mtTour": "Mountain Tour",
  "t_mtTwisty": "Mountain Twisty Road",
  "t_northloop": "Northloop",
  "t_northloop1": "Northloop 1",
  "t_northloop2": "Northloop 2",
  "t_northloop3": "Northloop 3",
  "t_northloop4": "Northloop 4",
  "t_northloop5": "Northloop 5",
  "t_oceanSlalom": "Ocean Beach Slalom",
  "t_oceanCity": "Ocean City Streets",
  "t_oceanHighway": "Ocean Highway",
  "t_oceanLongDrag": "Ocean Long Drag",
  "t_oceanParking": "Ocean Parking Lot",
  "t_oceanShortDrag": "Ocean Short Drag",
  "t_rallyMed": "Rallycross Medium",
  "t_rallySmall": "Rallycross Small",
  "t_riverSprint": "River Sprint",
  "t_runwayDrag": "Runway Drag UK Midlan",
  "t_runwayDragSlalom": "Runway Drag & Slalom",
  "t_runwayLoop": "Runway Loop",
  "t_serviceRoads": "Service Roads",
  "t_slalom": "Slalom Test",
  "t_slalomr": "Slalom Test (R)",
  "t_speedbump12km": "Speedbump 1/2km",
  "t_speedbump1km": "Speedbump 1km",
  "t_testBowl": "Test Bowl",
  "t_testBowlr": "Test Bowl (R)",
  "t_tokyoBridge": "Tokyo Bridge",
  "t_tokyoDrag": "Tokyo Drag",
  "t_tokyoGforce": "Tokyo G-Force",
  "t_tokyoLoop": "Tokyo Loop (R)",
  "t_tokyoOffRamp": "Tokyo Off Ramp (R)",
  "t_tokyoOverpass": "Tokyo Overpass (R)",
  "t_tCircuit": "Twisty Circuit",
  "t_tCircuitr": "Twisty Circuit (R)",
  "t_tRoad": "Twisty Road",
  "t_waterDrag": "Waterfront Drag",
  "s_dry": "Dry",
  "s_aspht": "Aspht",
  "s_wet": "Wet",
  "s_dirt": "Dirt",
  "s_gravel": "Gravel",
  "s_ice": "Ice",
  "s_sand": "Sand",
  "s_snow": "Snow",
  "s_grass": "Grass",
  "g_joinDiscord": "Pripoj sa k serveru TDR Discord a porozprávaj sa o zlepšení tvojej šablóny alebo o tom, prečo bola tvoja šablóna odmietnutá.",
  "g_rule0": "Tvoja šablóna by mala mať kontext pre vozidlá, ktoré dávajú zmysel. Rovnaká značka, rovnaká krajina, rovnaké pneumatiky, rovnaká značka, rovnaká trieda... Alebo akýkoľvek kontext ako robí Hutch, ako požiadavky na eventy/challenge.",
  "g_rule1": "Mal by ste uviesť všetky dostupné autá pre daný kontext alebo sa aspoň pokúsiť pridať všetky. Nedávaj len autá, ktoré máš.",
  "g_rule2": "Šablóna by mala porovnávať autá s podobnými štatistikami. Nemá zmysel porovnávať RWD Perf s 4WD All-surface na suchých asfaltových tratiach.",
  "g_rule3": "Porovnávaj na cestných tratiach s terénnymi iba vtedy, ak je na to dôvod.",
  "g_rule4": "Nie je potrebné zahrnúť všetky dostupné trate v hre. Môže to mať za následok výkonnostné problémy aplikácie.",
  "g_rule5": "Snaž sa zoradiť trate podľa typu. Dragy zvlášť a povrchy zvlášť.",
  "g_rule6": "Snaž sa zoradiť vozidlá podľa RQ levelu (najvyššie prvé) alebo podľa výkonu vozidiel na daných tratiach.",
  "g_rule7": "Nie je potrebné aby šablóna mala všetky časy vyplnené, avšak mal by si vložiť najviac čo môžeš pred schválením.",
  "g_rule8": "Zvoľ popis.",
  "g_rule9": "Pozri sa či šablóna pre dané trate už náhodou existuje.",
  "t_butte": null,
  "t_canyonLookout": null,
  "t_canyonDtRoad": null,
  t_canyonButte: null,
  t_lookout: null,
  t_dtRoad: null,
}