
export default {
  m_campaign: "Campaign",
  m_home: "Home",
  m_compare: "Compare",
  m_challenges: "Challenges",
  m_event: "Event",
  m_events: "Events",
  m_charts: "Charts",
  m_options: "Options",
  m_about: "About",
  m_vChanges: "{version} Changes",
  m_vNewPhotos: "{version} New Photos",
  m_library: "Library",
  m_addLibrary: "Add to library",
  m_sharableLink: "Shareable link",
  m_copy: "Copy",
  m_listCars: "List of cars",
  m_type: "Type",
  m_search: "Search",
  m_searchCar: "Search car",
  m_searchTracks: "Search tracks",
  m_selectTrack: "Select track",
  m_contributors: "Contributors",
  m_printBy: "print by",
  m_display: "Display",
  m_colors: "Colors",
  m_more3dot: "More...",
  m_trackset: "Trackset",
  m_clear: "Clear",
  m_clearCars: "Clear cars",
  m_load: "Load",
  m_loadChart: "Load chart",
  m_memory: "Memory",
  m_image: "Image",
  m_pleaseWait3dot: "Please wait...",
  m_downloadPng: "Download PNG",
  m_save: "Save",
  m_cancel: "Cancel",
  m_login: "Login",
  m_gotoLogin: "Go to Login",
  m_email: "Email",
  m_emailVerification: "Email verification",
  m_username: "Username",
  m_password: "Password",
  m_newPassword: "New password",
  m_repeatPassword: "Repeat password",
  m_repeat: "Repeat",
  m_charLeast: "{n} char at least",
  m_register: "Register",
  m_forgot: "Forgot",
  m_allmostDone: "Almost done",
  m_here: "Here",
  m_filter: "Filter | Filters",
  m_change: "Change",
  m_analyze: "Analyze",
  m_submitReview: "Submit for review",
  m_saveToLibrary: "Save to library",
  m_delete: "Delete",
  m_approve: "Approve",
  m_round: "Round | Rounds",
  m_requirements: "Requirements",
  m_empty: "Empty",
  m_emptyRound: "Empty round",
  m_lockRound: "Lock this round for me",
  m_unlockRound: "Unlock this round",
  m_findBest: "Find the best",
  m_other: "Other",
  m_win: "win", // max 5 chars
  m_lose: "lose", // max 5 chars
  m_draw: "draw", // max 5 chars
  m_notime: "no time", // max 8 chars
  m_select: "select", // max 8 chars
  m_resetSolutions: "Reset solutions only",
  m_resetRound: "Reset round",
  m_includeDownvote: "Includes times with downvote?",
  m_showDataFromPast: "Show data from old versions",
  m_showOldTags: "Show deprecated tags",
  m_challengeName: "Challenge's name",
  m_eventName: "Event's name",
  m_numberRounds: "Number of rounds",
  m_yes: "Yes",
  m_no: "No",
  m_new: "New",
  m_by: "by", // small case
  m_checkOut: "Check out",
  m_templateGuide: "Template Guidelines",
  m_createNewCg: "Create new challenge",
  m_createNewEvent: "Create new event",
  m_title: "Title",
  m_twisty: "Twisty",
  m_drag: "Drag",
  m_city: "City",
  m_rqLimit: "RQ limit",
  m_points: "Points",
  m_time: "Time",
  m_timeToBeat: "Time to beat",
  m_uploadPrint: "Upload print",
  m_done: "Done",
  m_sort: "Sort",
  m_send: "Send",
  m_backHome: "Back to home",
  m_any: "Any",
  m_rarity: "Rarity",
  m_car: "Car | Cars",
  m_changed: "Changed",
  m_noRecords: "No records",
  m_showOtherTracks: "Show other tracks",
  m_showMore: "Show more",
  m_lastContribution: "Last contributions",
  m_nothingFound: "Nothing found",
  m_changedCars: "changed cars", // small case
  m_uploadSuccess: "Upload successful",
  m_noData: "No data to show",
  m_noTimesFound: "No times found",
  m_logoutSuccess: "Logout successful",
  m_loginSuccess: "You logged in",
  m_deleteSuccess: "Successful delete",
  m_saveSuccess: "Successful save",
  m_approveSuccess: "Successful approve",
  m_sentReview: "Submited for review",
  m_language: "Language",
  m_logout: "Logout",
  m_resetSavedHand: "Reset to initial solution",
  m_showCustomTunes: "Show custom tunes",
  m_kingForceVertical: "Change display to vertical",
  m_showBigCards: "Show big cards",
  m_chartHideOutOfFilter: "Hide cars out of filter",
  m_meta: "Meta",
  m_race: "Race",
  m_useTrackList: "Use track list",
  m_modShowSuspectTimes: "Hide suspect times",
  m_newByCopy: "New by copy",
  m_eventShowOnlyPicks: "Show picks only",
  m_eventForcePicks: "Force show picks",
  m_showPointsCgForce: "show points",
  m_statsView: "Stats view",
  m_multi: "Multi",
  m_multiTags: "Multiple tags",
  m_hidden: "Hidden",
  m_enablePicks: "Enable picks",
  m_clearPicks: "Clear picks",
  m_findRqSavers: "Find RQ savers",
  m_homePointsToggle: "Show a toggle to show points",
  m_zoom: "Zoom",
  m_review: "Review | Reviews",
  m_addReview: "Add review",
  m_clubs: "Clubs",
  m_showContributors: "Show car contributors",
  m_tracksetName: "Trackset's name",
  m_tracksets: "Tracksets",
  m_reqs: "Reqs",
  m_reqsName: "Requirements's name",
  m_daySelector: "Day selector",
  m_current: "current",
  m_saveDay: "Save day",
  m_createNewTrackset: "Create new trackset",
  m_createNewReqs: "Create new requirement",
  m_saveTrackset: "Save trackset",
  m_saveReqs: "Save requirement",
  m_saveOriginalOrder: "Save as original order",
  m_showOriginalOrder: "Show original order",
  m_filterPage: "Filter page",
  m_notCurrent: "Not current",
  m_admin: "Admin",
  m_carFinder: "Car Finder",
  m_newPhotos: "New Photos",
  m_testPoints: "Test Points",
  m_downTimes: "Downvoted Times",
  m_translationTool: "Translation Tool",
  m_newCar: "New Car",
  m_relativeBest: "Relative to best",
  m_2xmultiplier: "2x Multiplier",
  m_cheatSheet: "Cheatsheet",
  m_simulateUntilGetAll: "Simulate until get all",
  m_simulateUntilGetOne: "Simulate until get one",
  m_simulate: "Simulate",
  m_simulating: "Simulating",
  m_impossibleRemoved: "{count} impossible cars removed",
  m_count: "Count",
  m_quantityOfCars: "Quantity of cars",
  m_success: "Success",
  m_failed: "Failed",
  m_status: "Status",
  m_cost: "Cost",
  m_stop: "Stop",
  m_probabilityPerOpen: "Probability per open",
  m_cumulativeProbability: "Cumulative probability",
  m_superLucky: "Super Lucky",
  m_veryLucky: "Very Lucky",
  m_goodLuck: "Good Luck",
  m_littleLucky: "Little Lucky",
  m_badLuck: "Bad Luck",
  m_worstLuck: "Worst Luck",
  m_zeroLuck: "Zero Luck",
  m_impossible: "Impossible",
  m_customizePack: "Customize pack",
  m_impossiblePack: "This pack isn't possible",
  m_impossibleCondition: "This condition isn't possible",
  m_permanents: "Permanents",
  m_linkDiscord: "Link your discord account",
  m_sixDigitCode: "Six digit code",
  m_discordUserId: "Discord User ID",
  m_discordLinkFinish: "Your TDR and Discord accounts are now linked!",
  m_submitYourCommunity: "Submit my community",
  m_wantToJoin: "Want to join?",
  m_private: "Private",
  m_public: "Public",
  m_official: "Official",
  m_platform: "Platform",
  m_selectPlatform: "Select platform",
  m_selectCountry: "Select country",
  m_userCount: "User count",
  m_description: "Description",
  m_optional: "Optional",
  m_informationForInvite: "Information for invite",
  m_link: "Link",
  m_unlinkDiscord: "Unlink my discord",
  m_inviter: "Inviter",
  m_requestSent: "Request sent",
  m_edit: "Edit",
  m_saveToFile: "Save to file",
  m_loadFile: "Load file",
  m_replace: "Replace",
  m_eventPack: "Event pack",
  m_accelCurveMaker: "Acceleration Curve Maker",
  m_speed: "Speed",
  m_clearBoard: "Clear board",

  
  
  p_userSentCgForAnalyse: "Thanks! Your round will be analysed. Join Discord to talk about if you want.",
  p_emptyRoundForUser: "This round isn't done yet. You can help creating it, then, submiting for review.",
  p_emptyRoundVoteForUser: "Is everything ok with this submit?",
  p_emptyRound2: "This round isn't done yet. If you are on this round, you can help to include it.",
  p_emptyRoundLogin: "Login to start creating this round or contact a moderator on our discord server:",
  p_lockRoundPhrase: "Lock this round to you to start doing it",
  p_modDoingRound: "{mod} is doing this round",
  p_patronsOnly: "This feature is available for patrons only (Tier {tier}).",
  p_bestOfDescription: "It returns a list of the best cars for the given track and filter. An example:",
  p_chartsDescription: "It puts all database of a track into a chart. For a demonstration:",
  p_templateWarning: "Make sure that your template is useful to the community or it will not be approved.",
  p_about1: "This project was made by TiagoXavi and is not related to the Hutch Games Ltd.",
  p_about2: "Any problem or suggestion, please join the Discord server or send an email (mighty.boy@topdrivesrecords.com).",
  p_about3: "TDR is free for everyone and ad-free. You can support the project to keep it online and receiving new features!",
  p_resetPassHelper: "Type your email to receive a link to reset your password",
  p_resetPassIfExist: "If exist a user with this email, a link to reset password has been sent.",
  p_confirmationWillSent: "Confirmation email will be sent",
  p_checkYourEmailBox: "Please, check your email box",
  p_spamToo: "Spam box too!",
  p_somethingWrong: "Something went wrong",
  p_beforeLeave: "Are you sure you want to leave?",
  p_eventsKingDescription: "You can only see 1 car per rarity. Here is how the complete list works:",
  p_eventsKingLogin: "Login to see the best car for each rarity",
  p_eventHiddenForPatreons: "This event is a preview available for patreons at least tier 3",
  p_linkDiscordExplanationCommu: "To submit your private community, you need:",
  p_linkDiscordExplanationP1: "To link your account, you need:",
  p_linkDiscordExplanationP2: "• A Discord account • Be on TDR Discord server • Get your Discord User ID (use the command /user on TDR Discord server)",
  p_linkDiscordCodeSent: "A code has been sent as a private message on Discord.",
  p_informationForInvite: "What information people need to send for joining your community, like email, phone, username... This will be displayed for users who wants to join your community",
  p_inviteYourDiscord: "You will receive join requests on your discord, keep an eye on them",
  p_privateDescript: "People will request to enter \n(You need a discord account)",
  p_publicDescript: "There is a public link and anyone can enter",
  p_userSentCommunityForAnalyse: "Thanks! Your community will be analysed by TDR mod team.",
  p_communityToBeInvited: "To be invited, type what is being asked below:",
  
  g_joinDiscord: "Join the TDR Discord server to talk about improvement of your template or why your template has been declined.",
  g_rule0: "Your template should have a context for cars that make sense. Like same tag, same country, same tires, same brand, same class... Or any context like Hutch does as requirements for events/challenges.",
  g_rule1: "You should put all cars available for that context or at least try to add all. Don't put only cars that you have.",
  g_rule2: "The template should compare cars with similar stats. Don't make sense comparing a RWD Perf with 4WD All-surface on dry asphalt tracks.",
  g_rule3: "Only mix on road tracks with off road tracks if there is a reason for that.",
  g_rule4: "Don't need to put all tracks available in the game. The app will have performance issues.",
  g_rule5: "Try to sort tracks by type like grouping drag tracks and same surface tracks.",
  g_rule6: "Try to sort cars by RQ (high first) or by performance on selected tracks.",
  g_rule7: "The template don't need to have all times filled, but you could fill what you can before submit.",
  g_rule8: "Use a descriptive title.",
  g_rule9: "Take a look if already exists a template for the same purpose as yours.",

  s_dry: "Dry",  // max 5 chars
  s_aspht: "Aspht",  // max 5 chars
  s_wet: "Wet",  // max 5 chars
  s_dirt: "Dirt",  // max 5 chars
  s_gravel: "Gravel",  // max 5 chars
  s_ice: "Ice",  // max 5 chars
  s_sand: "Sand",  // max 5 chars
  s_snow: "Snow",  // max 5 chars
  s_grass: "Grass",  // max 5 chars

  c_topSpeed: "Top speed",
  c_handling: "Handling",
  c_hand: "@:c_handling | @:c_handling",
  c_class: "Class | Classes",
  c_year: "Year | Years",
  c_tyre: "Tyre | Tyres",
  c_drive: "Drive | Drives",
  c_clearance: "Clearance | Clearances",
  c_country: "Country | Countries",
  c_brand: "Brand | Brands",
  c_tag: "Tag | Tags",
  c_stock: "Stock",
  c_weight: "Weight",
  c_fuel: "Fuel",
  c_seats: "Seats",
  c_enginePos: "Engine pos",
  c_brakeClass: "Brake class",
  c_brake: "Brake",
  c_bodyStyle: "Body style",
  c_prizeCar: "Prize Car",
  'c_prize cars': "Prize Cars",
  'c_non-prize cars': "Non-Prize Cars",
  c_mid: "Med",
  c_low: "Low",
  c_high: "High",
  c_performance: "Performance",
  c_standard: "Standard",
  'c_all-surface': "All-surface",
  'c_off-road': "Off-road",
  c_slick: "Slick",
  c_saloon: "Saloon",
  c_roadster: "Roadster",
  c_coupe: "Coupe",
  c_hatchback: "Hatchback",
  c_suv: "SUV",
  c_convertible: "Convertible",
  c_estate: "Estate",
  c_pickup: "Pickup",
  c_mpv: "MPV",
  c_van: "Van",
  c_dragster: "Dragster",
  c_twister: "Twister",
  c_speedster: "Speedster",
  'c_all-rounder': "All-rounder",
  c_petrol: "Petrol",
  c_diesel: "Diesel",
  c_electric: "Electric",
  c_hybrid: "Hybrid",
  c_bioethanol: "Bioethanol",
  c_hydrogen: "Hydrogen",
  c_misc: "Misc",
  c_frontEngine: "Front",
  c_midEngine: "Mid",
  "c_mid-rearEngine": "Mid-rear",
  c_mixedEngine: "Mixed",
  c_rearEngine: "Rear",
  c_tune: "Tune",
  c_name: "Name",
  c_template: "Template",
  
  t_drag100b: "0-100-0mph",
  t_drag100: "0-100mph",
  t_drag120: "0-120mph",
  t_drag150b: "0-150-0mph",
  t_drag150: "0-150mph",
  t_drag170: "0-170mph",
  t_drag200: "0-200mph",
  t_drag60b: "0-60-0mph",
  t_drag60: "0-60mph",
  t_mile1: "1 Mile Drag",
  t_mile1r: "1 Mile Drag (R)",
  t_mile2: "1/2 Mile Drag",
  t_mile4: "1/4 Mile Drag",
  t_mile4r: "1/4 Mile Drag (R)",
  t_drag100150: "100-150mph (R)",
  t_drag30130: "30-130mph (R)",
  t_drag50150: "50-150mph (R)",
  t_drag75125: "75-125mph (R)",
  t_airplaneHangars: "Airplane Hangars",
  t_airplaneSlalom: "Airplane Slalom",
  t_smallRoad: "Back Road",
  t_butte: "Butte",
  t_canyonButte: "Canyon Butte",
  t_lookout: "Lookout",
  t_canyonLookout: "Canyon Lookout",
  t_dtRoad: "Dirt Road",
  t_canyonDtRoad: "Canyon Dirt Road",
  t_canyonTour: "Canyon Tour",
  t_carPark: "Car Park",
  t_townSlalom: "City Slalom",
  t_townSprint: "City Sprint",
  t_csMed: "City Streets Medium",
  t_csSmall: "City Streets Small",
  t_townTour: "City Tour",
  t_cross: "Cross-Country",
  t_fast: "Fast Circuit",
  t_fastr: "Fast Circuit (R)",
  t_fieldSlalom: "Field Slalom",
  t_figureEight: "Figure Of Eight",
  t_forestRiver: "Forest River Crossing",
  t_forest: "Forest Road",
  t_forestSlalom: "Forest Slalom",
  t_frozenLake: "Frozen Lake",
  t_frozenRivSla: "Frozen River Slalom",
  t_gForce: "G-Force Test",
  t_gForcer: "G-Force Test (R)",
  t_hairpin: "Hairpin Road",
  t_hClimb: "Hill Climb",
  t_hClimbr: "Hill Climb (R)",
  t_indoorKart: "Indoor Karting",
  t_kart: "Karting Circuit",
  t_draglshape: "L-Shape Drag",
  t_mnGforce: "Monaco G-Force Test",
  t_mnHairpin: "Monaco Hairpin",
  t_mnCityNarrow: "Monaco Narrow Streets",
  t_mnCity: "Monaco Streets",
  t_mnCityLong: "Monaco Streets Long",
  t_moto: "Motocross Track",
  t_mtHairpin: "Mountain Hairpin",
  t_mtHill: "Mountain Hill Climb",
  t_mtIncline: "Mountain Incline Road",
  t_mtSlalom: "Mountain Slalom",
  t_mtTour: "Mountain Tour",
  t_mtTwisty: "Mountain Twisty Road",
  t_northloop: "North Loop",
  t_northloop1: "North Loop Relay 1",
  t_northloop2: "North Loop Relay 2",
  t_northloop3: "North Loop Relay 3",
  t_northloop4: "North Loop Relay 4",
  t_northloop5: "North Loop Relay 5",
  t_oceanSlalom: "Ocean Beach Slalom",
  t_oceanCity: "Ocean City Streets",
  t_oceanHighway: "Ocean Highway",
  t_oceanLongDrag: "Ocean Long Drag",
  t_oceanParking: "Ocean Parking Lot",
  t_oceanShortDrag: "Ocean Short Drag",
  t_rallyMed: "Rallycross Medium Circuit",
  t_rallySmall: "Rallycross Small Circuit",
  t_riverSprint: "River Sprint",
  t_runwayDrag: "Runway Drag",
  t_runwayDragSlalom: "Runway Drag & Slalom",
  t_runwayLoop: "Runway Loop",
  t_serviceRoads: "Service Roads",
  t_slalom: "Slalom Test",
  t_slalomr: "Slalom Test (R)",
  t_speedbump12km: "Speedbump 1/2 km",
  t_speedbump1km: "Speedbump 1 km",
  t_testBowl: "Test Bowl",
  t_testBowlr: "Test Bowl (R)",
  t_tokyoBridge: "Tokyo Bridge",
  t_tokyoDrag: "Tokyo Drag",
  t_tokyoGforce: "Tokyo G-Force Test",
  t_tokyoLoop: "Tokyo Loop (R)",
  t_tokyoOffRamp: "Tokyo Off-Ramp (R)",
  t_tokyoOverpass: "Tokyo Overpass (R)",
  t_tCircuit: "Twisty Circuit",
  t_tCircuitr: "Twisty Circuit (R)",
  t_tRoad: "Twisty Road",
  t_waterDrag: "Waterfront Drag",
  t_laguna: "Laguna Seca",
  t_lumberTwisty: "Lumber Mill Twisty Circuit",
  t_desertHill: "Desert Rally Hill",
  t_desertSmall: "Desert Rally Small",
  t_desertBig: "Desert Rally Big",
  t_desertRallyDirt: "Desert Rally Dirt",
  t_dockAltCirc: "Dockyard Alternative Circuit",
  t_dockCirc: "Dockyard Circuit",
  t_dockCity: "Dockyard City Streets",
  t_dockGforceDrag: "Dockyard G-Force Drag",
  t_dockKart: "Dockyard Karting",
  t_dockUdrag: "Dockyard U-Drag",
  t_lumberForest: "Lumber Mill Forest Rally",
  t_lumberRiver: "Lumber Mill River Rally",
  t_drag62: "0-62mph",
  t_drag124: "0-124mph",
  t_dealsGap1: "Deals Gap Relay 1",
  t_dealsGap2: "Deals Gap Relay 2",
  t_dealsGap3: "Deals Gap Relay 3",
  t_dealsGap4: "Deals Gap Relay 4",
  t_dealsGap5: "Deals Gap Relay 5",
  t_dealsGap: "Deals Gap",
  t_dealsGapBack: "Deals Gap Backroads",
  t_nwCircuit: "New York Circuit",
  t_nwDrag: "New York Drag",
  t_nwGforce: "New York G-Force",
  t_nwLoop: "New York Times Square Loop",
  t_nwCathedral: "New York Cathedral",
  t_nwTour: "New York Central Park Tour",
  t_nwSlalom: "New York Train Slalom"
}