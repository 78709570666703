export default {
  "language": "German",
  "m_campaign": "Kampagne",
  "m_home": "Startseite",
  "m_compare": "Vergleichen",
  "m_challenges": "Herausforderungen",
  "m_event": "Event",
  "m_events": "Events",
  "m_charts": "Grafiken",
  "m_options": "Optionen",
  "m_about": "Über",
  "m_library": "Bibliothek",
  "m_addLibrary": "zur Bibliothek hinzufügen",
  "m_sharableLink": "Teilbarer Link",
  "m_copy": "Kopieren",
  "m_listCars": "Liste von Autos",
  "m_type": "Typ",
  "m_search": "Suchen",
  "m_searchCar": "Suche ein Auto",
  "m_searchTracks": "Suche eine Strecke",
  "m_selectTrack": "Wähle eine Strecke",
  "m_contributors": "Mitwirkende",
  "m_colors": "Farben",
  "m_more3dot": "Mehr...",
  "m_trackset": "Strecken",
  "m_clear": "Entfernen",
  "m_display": "Ansicht",
  "m_printBy": "screenshot von",
  "m_clearCars": "Entferne Autos",
  "m_load": "Laden",
  "m_loadChart": "Grafik laden",
  "m_image": "Bild",
  "m_pleaseWait3dot": "Bitte warten...",
  "m_downloadPng": "PNG herunterladen",
  "m_save": "Speichern",
  "m_cancel": "Abbrechen",
  "m_login": "Anmeldung",
  "m_vChanges": "{version} Veränderungen",
  "m_memory": "Speicherplatz",
  "m_gotoLogin": "Gehe zur Anmeldung",
  "m_email": "E-Mail",
  "m_emailVerification": "E-Mail Verifizierung",
  "m_username": "Benutzername",
  "m_password": "Passwort",
  "m_newPassword": "Neues Passwort",
  "m_repeatPassword": "Passwort wiederholen",
  "m_repeat": "Wiederholen",
  "m_charLeast": "mindestens {n} Zeichen",
  "m_register": "Registrieren",
  "m_forgot": "Vergessen",
  "m_allmostDone": "Fast fertig",
  "m_here": "Hier",
  "m_filter": "Filter | Filter",
  "m_change": "Ändern",
  "m_analyze": "Analysieren",
  "m_submitReview": "Zur Überprüfung einreichen",
  "m_saveToLibrary": "In der Bibliothek speichern",
  "m_delete": "Löschen",
  "m_approve": "Bestätigen",
  "m_round": "Runde | Runden",
  "m_requirements": "Anforderungen",
  "m_empty": "Leer",
  "m_emptyRound": "Leere Runde",
  "m_lockRound": "Diese Runde für mich sperren",
  "m_unlockRound": "Diese Runde entsperren",
  "m_findBest": "Finde den Besten",
  "m_other": "Andere",
  "m_win": "Sieg",
  "m_lose": "Pleite",
  "m_draw": "Remis",
  "m_notime": "keine Zeit",
  "m_select": "auswählen",
  "m_resetSolutions": "Nur Lösungen zurücksetzen",
  "m_resetRound": "Runde zurücksetzen",
  "m_includeDownvote": "Beinhalte Zeiten mit suspekten Angaben?",
  "m_showDataFromPast": "Zeige Daten auch von alten Versionen",
  "m_showOldTags": "Zeige veraltete Sammlungen",
  "m_challengeName": "Herausforderungs Name",
  "m_eventName": "Event Name",
  "m_numberRounds": "Anzahl der Runden",
  "m_yes": "Ja",
  "m_no": "Nein",
  "m_new": "Neu",
  "m_by": "von",
  "m_templateGuide": " Richtlinie für Vorlagen",
  "m_checkOut": "Probiere es aus",
  "m_createNewCg": "Neue Herausforderung erstellen",
  "m_createNewEvent": "Neues Event erstellen",
  "m_title": "Titel",
  "m_twisty": "Kurvig",
  "m_drag": "Drag",
  "m_city": "Stadt",
  "m_rqLimit": "RQ Grenze",
  "m_points": "Punkte",
  "m_time": "Zeit",
  "m_timeToBeat": "zu schlagende Zeit",
  "m_uploadPrint": "Foto hochladen",
  "m_done": "Erledigt",
  "m_sort": "Sortieren",
  "m_send": "Senden",
  "m_backHome": "Zurück zur Startseite",
  "m_any": "Beliebig",
  "m_rarity": "Seltenheit",
  "m_car": "Auto | Autos",
  "m_changed": "Geändert",
  "m_noRecords": "Keine Einträge",
  "m_showOtherTracks": "Andere Strecken zeigen",
  "m_showMore": "Mehr zeigen",
  "m_lastContribution": "Letzte Beiträge",
  "m_nothingFound": "Nichts gefunden",
  "m_changedCars": "veränderte Autos",
  "m_uploadSuccess": "Hochladen erfolgreich",
  "m_noData": "Kein Daten zum Anzeigen",
  "m_noTimesFound": "Keine Zeiten gefunden",
  "m_logoutSuccess": "Abmeldung erfolgreich",
  "m_loginSuccess": "Du hast dich angemeldet",
  "m_deleteSuccess": "Erfolgreich gelöscht",
  "m_saveSuccess": "Erfolgreich gespeichert",
  "m_approveSuccess": "Erfolgreich bestätigt",
  "m_sentReview": "Zur Überprüfung eingereicht",
  "m_language": "Sprache",
  "p_userSentCgForAnalyse": "Danke! Deine Runde wird analysiert. Trete dem Discord bei, um darüber zu sprechen und zu diskutieren, falls du willst.",
  "p_emptyRoundForUser": "Diese Runde ist noch nicht gemacht. Du kannst helfen sie zu erstellen und dann zur Überprüfung einreichen.",
  "p_emptyRound2": "Diese Runde ist noch nicht gemacht. Falls du bei dieser Runde bist, kannst du helfen sie zu erfassen.",
  "p_emptyRoundLogin": "Melde dich an, um diese Runde anzulegen oder kontaktiere einen Moderator in unserem Discord Server:",
  "p_lockRoundPhrase": "Blocke diese Runde für dich, um mit ihr zu starten",
  "p_modDoingRound": "{mod} macht diese Runde",
  "p_patronsOnly": "Dieses Feature ist nur für Patreons verfügbar (Rang {tier}).",
  "p_bestOfDescription": "Liefert dir eine Liste der besten Autos für die gegebene Strecke und den angewendeten Filter. Ein Beispiel:",
  "p_chartsDescription": "Legt alle Grunddaten einer Strecke in eine Grafik. Eine Demonstration:",
  "p_templateWarning": "Stelle sicher, dass deine Vorlage für die Allgemeinheit nützlich ist. Sonst wird sie nicht genehmigt.",
  "p_about1": "Dieses Projekt wurde von TiagoXavi gemacht und ist nicht zugehörig zur Hutch Games Ltd.",
  "p_about2": "Irgendwelche Probleme oder Vorschläge? Trete bitte dem Discord Server bei oder sende eine E-Mail an (mighty.boy@topdrivesrecords.com).",
  "p_about3": "TDR ist kostenlos für alle. Du kannst das Projekt unterstützen, um es online zu halten und um neue Features zu erhalten!",
  "p_resetPassHelper": "Gebe deine E-Mail an, um einen Link zur Zurücksetzung deines Passworts zu erhalten.",
  "p_resetPassIfExist": "Falls ein Benutzer mit dieser E-Mail existiert, wurde ein Link zur Zurücksetzung des Passworts gesendet.",
  "p_confirmationWillSent": "Bestätigungs-E-Mail wird gesendet",
  "p_checkYourEmailBox": "Bitte prüfe dein E-Mail Postfach",
  "p_spamToo": "Spam Ordner auch!",
  "p_somethingWrong": "Etwas ist schief gelaufen",
  "g_joinDiscord": "Trete dem Discord Server bei, um über Verbesserungen deiner Vorlagen zu reden oder weshalb deine Vorlagen abgelehnt wurden.",
  "g_rule0": "Deine Vorlagen sollten Autos beinhalten die einen sinnvollen Kontext zueinander haben. Wie gleicher Tag, gleiches Land, gleiche Reifen, gleiche Marke, gleiche Klasse... Oder irgendeinen Bezug zu Anforderungen die Hutch für Events/Herausforderungen benutzt.",
  "g_rule1": "Du solltest alle verfügbaren Autos für diesen Kontext hinzufügen oder zumindest versuchen alle hinzuzufügen. Nimm nicht nur Autos, die du besitzt.",
  "g_rule2": "Die Vorlage sollte Autos mit ähnlichen Werten vergleichen. Es macht keinen Sinn ein Auto, welches Hinterradantrieb und Leistungsreifen hat mit einem Auto, welches Allradantrieb und Allwetter Reifen hat zu vergleichen.",
  "g_rule3": "Vermische Asphalt Strecken mit Gelände Strecken nur wenn es dafür einen Grund gibt.",
  "g_rule4": "Es ist nicht nötig alle im Spiel verfügbaren Strecken hinzuzufügen. Die App wird Leistungsprobleme haben.",
  "g_rule5": "Versuche die Strecken nach Typen zu sortieren, z.B. Gruppierung von Drag Strecken, und nach gleicher Streckenoberfläche.",
  "g_rule6": "Versuche Autos nach RQ zu sortieren (hoch zuerst) oder nach Performance in den ausgewählten Strecken.",
  "g_rule7": "Nicht alle Zeiten der Vorlage müssen gefüllt sein, aber du könntest das ausfüllen, was du kannst, bevor du sie einreichst",
  "g_rule8": "Benutze einen beschreibenden Titel.",
  "g_rule9": "Schaue dich in der Bibliothek um, ob eine Vorlage mit dem gleichen Zweck bereits existiert.",
  "s_dry": "Trock",
  "s_aspht": "Aspht",
  "s_wet": "Nass",
  "s_dirt": "Erde",
  "s_gravel": "Schott",
  "s_ice": "Eis",
  "s_sand": "Sand ",
  "s_snow": "Schne",
  "s_grass": "Gras",
  "c_topSpeed": "Tempo",
  "c_handling": "Fahrverhalten",
  "c_hand": "@:c_handling | @:c_handling",
  "c_class": "Klasse | Klassen",
  "c_year": "Jahr | Jahre",
  "c_tyre": "Reifen | Reifen",
  "c_drive": "Antrieb | Antriebe",
  "c_clearance": "Bodenfreiheit | Bodenfreiheiten",
  "c_country": "Land | Länder",
  "c_brand": "Marke | Marken",
  "c_tag": "Sammlung | Sammlungen",
  "c_stock": "Ungetunt",
  "c_weight": "Gewicht",
  "c_fuel": "Kraftstoff",
  "c_seats": "Sitze",
  "c_enginePos": "Motorposition",
  "c_bodyStyle": "Chassis-Stil",
  "c_prizeCar": "Preisauto",
  "c_prize cars": "Preisautos",
  "c_non-prize cars": "Nicht-Preisautos",
  "c_mid": "Mittel",
  "c_low": "Niedrig",
  "c_high": "Hoch",
  "c_performance": "Leistung",
  "c_standard": "Standard",
  "c_all-surface": "Allwetter",
  "c_off-road": "Gelände",
  "c_slick": "Ohne Profil",
  "c_saloon": "Limousine",
  "c_roadster": "Roadster",
  "c_coupe": "Coupe",
  "c_hatchback": "Schrägheck",
  "c_suv": "SUV",
  "c_convertible": "Cabrio",
  "c_estate": "Kombi",
  "c_pickup": "Pickup",
  "c_mpv": "Minivan",
  "c_van": "Van",
  "c_dragster": "Dragster",
  "c_twister": "wendiges Auto",
  "c_all-rounder": "Alleskönner",
  "c_petrol": "Benzin",
  "c_diesel": "Diesel",
  "c_electric": "Elektrisch",
  "c_hybrid": "Hybrid",
  "c_bioethanol": "Bioethanol",
  "c_hydrogen": "Wasserstoff",
  "c_misc": "Divers",
  "c_frontEngine": "Vorne",
  "c_midEngine": "Mitte",
  "c_mixedEngine": "Verteilt",
  "c_mid-rearEngine": "Mittelmotor",
  "c_rearEngine": "Hinten",
  "t_drag100b": "0-100-0mph",
  "t_drag100": "0-100mph",
  "t_drag120": "0-120mph",
  "t_drag150b": "0-150-0mph",
  "t_drag150": "0-150mph",
  "t_drag170": "0-170mph",
  "t_drag200": "0-200mph",
  "t_drag60b": "0-60-0mph",
  "t_drag60": "0-60mph",
  "t_mile1": "Drag 1 Meile",
  "t_mile1r": "Drag 1 Meile (R)",
  "t_mile2": "Drag 1/2 Meile",
  "t_mile4": "Drag 1/4 Meile",
  "t_mile4r": "Drag 1/4 Meile (R)",
  "t_drag100150": "100-150mph (R)",
  "t_drag30130": "30-130mph (R)",
  "t_drag50150": "50-150mph (R)",
  "t_drag75125": "75-125mph (R)",
  "t_airplaneSlalom": "Flugzeug-Slalom",
  "t_canyonTour": "Canyon-Tour",
  "t_carPark": "Parkhaus",
  "t_townSlalom": "Stadt Slalom",
  "t_townSprint": "Stadt Sprint",
  "t_csMed": "Stadtstr. Mittel",
  "t_csSmall": "Stadtstr. Klein",
  "t_fast": "Schnellrunde",
  "t_fastr": "Schnellrunde (R)",
  "t_figureEight": "8-Form",
  "t_forestRiver": "Wald-Flussquerung",
  "t_forestSlalom": "Wald Slalom",
  "t_forest": "Waldweg",
  "t_frozenLake": "Eissee",
  "t_gForce": "G-Kraft-Test",
  "t_gForcer": "G-Kraft-Test (R)",
  "t_hairpin": "Haarnadelkurven",
  "t_hClimb": "Bergrennen",
  "t_hClimbr": "Bergrennen (R)",
  "t_indoorKart": "Indoor-Kart",
  "t_kart": "Kartrunde",
  "t_draglshape": "L-Form-Drag",
  "t_mnGforce": "Monaco-G-Kraft-Test",
  "t_mnHairpin": "Monaco-Haarnadel",
  "t_mnCityNarrow": "Monaco-Gassen",
  "t_mnCity": "Monaco-Innenstadt",
  "t_mnCityLong": "Monaco-Innenstadt (Lang)",
  "t_moto": "Motocross-Strecke",
  "t_mtHairpin": "Gebirge-Haarnadel",
  "t_mtTwisty": "Gebirge-Kurven",
  "t_oceanSlalom": "Strandslalom am Meer",
  "t_rallyMed": "Rallyecross-Runde Mittel",
  "t_rallySmall": "Rallyecross-Runde Klein",
  "t_runwayDrag": "Startbahn-Drag",
  "t_slalom": "Slalom-Test",
  "t_slalomr": "Slalom-Test (R)",
  "t_testBowl": "Testschale",
  "t_testBowlr": "Testschale (R)",
  "t_tokyoLoop": "Tokio-Schleife (R)",
  "t_tokyoOffRamp": "Tokio-Rampe (R)",
  "t_tokyoOverpass": "Tokio-Querung (R)",
  "t_tCircuit": "Kurvenreiche Runde ",
  "t_tCircuitr": "Kurvenreiche Runde (R)",
  "t_tRoad": "Kurvenreiche Straße",
  "t_waterDrag": "Hafen-Drag",
  "t_airplaneHangars": "Flugzeughallen",
  "t_smallRoad": "Nebenweg",
  "t_townTour": "Stadt-Tour",
  "t_frozenRivSla": "Zugefrorener Fluss-Slalom",
  "t_mtHill": "Gebirge-Aufstieg",
  "t_mtSlalom": "Gebirge-Slalom",
  "t_mtTour": "Bergtour",
  "t_northloop": "North Loop",
  "t_northloop1": "North Loop Relay 1",
  "t_northloop2": "North Loop Relay 2",
  "t_northloop4": "North Loop Relay 4",
  "t_northloop3": "North Loop Relay 3",
  "t_northloop5": "North Loop Relay 5",
  "t_oceanCity": "Innenstadt am Meer",
  "t_oceanHighway": "Highway am Meer",
  "t_oceanLongDrag": "Langer Drag am Meer",
  "t_oceanParking": "Parkplatz am Meer",
  "t_oceanShortDrag": "Kurzer Drag am Meer",
  "t_runwayDragSlalom": "Startbahn-Drag&-Slalom",
  "t_runwayLoop": "Startbahn-Schleife",
  "t_serviceRoads": "Wartungsstraßen",
  "t_speedbump12km": "Bodenschwelle 1/2 km",
  "t_speedbump1km": "Bodenschwelle 1 km",
  "t_tokyoBridge": "Tokio-Brücke",
  "t_tokyoDrag": "Tokio-Drag",
  "t_tokyoGforce": "Tokio-G-Kraft-Test",
  "t_cross": "Cross-Country",
  "t_fieldSlalom": "Feld-Slalom",
  "t_mtIncline": "Gebirge-Anstieg",
  "t_riverSprint": "Fluss Sprint",
  "t_butte": "Kuppe",
  "t_canyonLookout": "Canyon-Ausguck",
  "t_canyonDtRoad": "Canyon-Schotter",
  "t_canyonButte": "Canyon-Kuppe",
  "t_lookout": "Ausguck",
  "t_dtRoad": "Schmutzige Straße",
  "t_dockKart": "Werft (Kart)",
  "t_dockUdrag": "Werft (U-Drag)",
  "t_lumberTwisty": "Lumber Mill (Kurvenreiche Runde)",
  "t_dockAltCirc": "Alternative Werft-Rennstrecke",
  "t_dockCirc": "Werft-Rennstrecke",
  "t_dockCity": "Werft (Stadtstraßen)",
  "t_dockGforceDrag": "Werft (G-Force Drag)",
  "t_lumberForest": "Lumber Mill (Wald-Rally)",
  "t_lumberRiver": "Lumber Mill (Fluss-Rally)",
  "m_vNewPhotos": "{version} Neue Bilder",
  "m_logout": "Abmeldung",
  "m_resetSavedHand": "Zürücksetzen auf ursprünglüche Lösung",
  "m_showCustomTunes": "Zeige benutzdefinierte Tunings",
  "m_kingForceVertical": "Zu vertikale Anzeige wechseln",
  "m_showBigCards": "Zeige große Karten",
  "m_race": "Rennen",
  "m_useTrackList": "Streckenliste verwenden",
  "m_modShowSuspectTimes": "Suspekte Zeiten verbergen",
  "m_newByCopy": "Kopie als neuen Eintrag erstellen",
  "m_eventShowOnlyPicks": "Zeige nur Bilder",
  "m_showPointsCgForce": "zeige punkte",
  "m_statsView": "Ansicht Statistik",
  "m_multiTags": "Mehrere Tags",
  "m_hidden": "Verborgen",
  "m_findRqSavers": "Finde RQ Savers",
  "m_homePointsToggle": "Zeige einen Schalter um Punkte anzuzeigen",
  "m_showContributors": "Zeige Mitwirkende von diesem Auto",
  "m_tracksetName": "Name der Streckenliste",
  "m_tracksets": "Streckenlisten",
  "m_reqsName": "Name der Anforderungen",
  "m_current": "aktuell",
  "m_saveDay": "Tag speichern",
  "m_daySelector": "Tag Auswähler",
  "m_createNewTrackset": "Neue Streckenliste erstellen",
  "m_createNewReqs": "Neue Anforderung erstellen",
  "m_saveTrackset": "Streckenliste speichern",
  "m_saveReqs": "Anforderung speichern",
  "m_saveOriginalOrder": "In originaler Reihenfolge speichern",
  "m_showOriginalOrder": "Originale Reihenfolge speichern",
  "m_filterPage": "Seite filtern",
  "m_notCurrent": "Nicht aktuell",
  "m_carFinder": "Auto Suche",
  "m_newPhotos": "Neue Bilder",
  "m_downTimes": "Suspekte Zeiten",
  "m_translationTool": "Übersetzungstool",
  "m_newCar": "Neues Auto",
  "m_relativeBest": "in Relation zum Besten",
  "m_2xmultiplier": "2x Multiplikator",
  "m_cheatSheet": "Spickzettel",
  "m_simulateUntilGetAll": "Simulieren bis du alle bekommst",
  "m_simulateUntilGetOne": "Simulieren bis du eins bekommst",
  "m_simulate": "Simulieren",
  "m_simulating": "Simuliere",
  "m_impossibleRemoved": "{count} nicht mögliche Autos entfernt",
  "m_count": "Anzahl",
  "m_quantityOfCars": "Menge der Autos",
  "m_success": "Erfolg",
  "m_failed": "Fehlschlag",
  "m_cost": "Kosten",
  "m_probabilityPerOpen": "Wahrscheinlichkeit je Pack",
  "m_cumulativeProbability": "Kumulative Wahrscheinlichkeit",
  "m_littleLucky": "Kleines Glück",
  "m_goodLuck": "Großes Glück",
  "m_superLucky": "Super Glück",
  "m_veryLucky": "Sehr großes Glück",
  "m_badLuck": "Kein Glück",
  "m_zeroLuck": "Überhaupt gar kein Glück",
  "m_worstLuck": "Gar kein Glück",
  "m_impossible": "Unmöglich",
  "m_customizePack": "Pack anpassen",
  "m_impossiblePack": "Dieses Pack ist nicht möglich",
  "p_emptyRoundVoteForUser": "Ist alles okay mit dieser Einreichung?",
  "p_beforeLeave": "Bist du sicher, dass du gehen möchtest?",
  "p_eventsKingDescription": "Du kannst nur 1 Auto pro Seltenheit sehen. So sieht die vollständige Liste aus:",
  "p_eventsKingLogin": "Anmelden, um das beste Auto für jede Seltenheit zu sehen",
  "p_eventHiddenForPatreons": "Dieses Event ist eine Vorschau für Patreons ab Rang 3",
  "t_desertHill": "Wüsten-Rally (Hügel)",
  "t_desertSmall": "Wüsten-Rally (Klein)",
  "m_impossibleCondition": "Diese Bedingung ist nicht möglich",
  "c_brake": "Bremse",
  "c_brakeClass": "Bremsklasse",
  "t_desertBig": "Wüsten-Rally (Groß)",
  "t_desertRallyDirt": "Wüsten-Rally (Erde)",
  "m_chartHideOutOfFilter": null,
  "m_meta": null,
  "m_eventForcePicks": null,
  "m_multi": null,
  "m_enablePicks": null,
  "m_clearPicks": null,
  "m_zoom": null,
  "m_review": null,
  "m_addReview": null,
  "m_clubs": null,
  "m_reqs": null,
  "m_admin": null,
  "m_testPoints": null,
  "m_status": null,
  "m_stop": null,
  "m_openLimit": null,
  "m_permanents": null,
  "c_speedster": null,
  "c_tune": null,
  "c_name": null,
  "t_laguna": null,
  "t_drag62": null,
  "t_drag124": null,
  "t_dealsGap1": null,
  "t_dealsGap2": null,
  "t_dealsGap3": null,
  "t_dealsGap4": null,
  "t_dealsGap5": null,
  "t_dealsGap": null,
  "t_dealsGapBack": "Deals Gap Nebenwege",
  "t_nwCircuit": "New York Circuit",
  "t_nwDrag": "New York Drag",
  "t_nwGforce": "New York G-Force",
  "t_nwLoop": "New York Times-Square-Schleife",
  "t_nwCathedral": "New York Kathedrale",
  "t_nwTour": "New York Central-Park-Tour",
  "t_nwSlalom": "New York Zugslalom"
}