export default {
  "language": "Dutch",
  "m_campaign": "Campagne",
  "m_home": "Home",
  "m_compare": "Vergelijken",
  "m_challenges": "Uitdagingen",
  "m_event": "Evenement",
  "m_events": "Evenementen",
  "m_charts": "Tabel",
  "m_options": "Instellingen",
  "m_about": "Over",
  "m_vChanges": "{version} Wijzigingen",
  "m_library": "Bibliotheek",
  "m_addLibrary": "Toevoegen aan Bibliotheek",
  "m_sharableLink": "Deelbare link",
  "m_copy": "Kopiëren",
  "m_listCars": "Lijst van auto's",
  "m_type": null,
  "m_search": "Zoeken",
  "m_searchCar": "Zoek auto",
  "m_searchTracks": "Zoek circuit",
  "m_selectTrack": "Selecteer circuit",
  "m_contributors": "Bijdragers",
  "m_printBy": "afgedrukt door",
  "m_display": "Weergave",
  "m_colors": "Kleuren",
  "m_more3dot": "Meer...",
  "m_trackset": "Circuit set",
  "m_clear": "Wissen",
  "m_clearCars": "Auto's wissen",
  "m_load": "Laden",
  "m_loadChart": "Tabel laden",
  "m_memory": "Geheugen",
  "m_image": "Afbeelding",
  "m_pleaseWait3dot": "Een moment geduld a.u.b.",
  "m_downloadPng": "PNG downloaden",
  "m_save": "Opslaan",
  "m_cancel": "annuleren",
  "m_login": "Inloggen",
  "m_gotoLogin": "Naar Login",
  "m_email": "Email",
  "m_emailVerification": "Email verificatie",
  "m_username": "Gebruikersnaam",
  "m_password": "Wachtwoord",
  "m_newPassword": "Nieuw wachtwoord",
  "m_repeatPassword": "Wachtwoord herhalen",
  "m_repeat": "Herhalen",
  "m_charLeast": "tenminste {n} tekens",
  "m_register": "Registreren",
  "m_forgot": "Vergeten",
  "m_allmostDone": "Bijna klaar",
  "m_here": "Hier",
  "m_filter": "Filter | Filters",
  "m_change": "Veranderen",
  "m_analyze": "Analyseren",
  "m_submitReview": "Ter beoordeling indienen",
  "m_saveToLibrary": "Opslaan in bibliotheek",
  "m_delete": "Verwijderen",
  "m_approve": "Goedkeuren",
  "m_round": "Ronde | Rondes",
  "m_requirements": "Eisen",
  "m_empty": "Leeg",
  "m_emptyRound": "lege ronde",
  "m_lockRound": "Deze ronde voor mij versleutelen",
  "m_unlockRound": "Deze ronde ontsleutelen",
  "m_findBest": "Vind de beste",
  "m_other": "Overig",
  "m_win": "win",
  "m_lose": "verlies",
  "m_draw": "remise",
  "m_notime": "geen tijd",
  "m_select": "selecteren",
  "m_resetSolutions": "Oplossingen resetten",
  "m_resetRound": "Ronde resetten",
  "m_includeDownvote": "Tijden met downvotes includeren?",
  "m_showDataFromPast": "Laat data van oudere versies zien",
  "m_showOldTags": "Laat verouderde labels zien",
  "m_challengeName": "Naam van de uitdaging",
  "m_eventName": "Evenementnaam",
  "m_numberRounds": "Aantal rondes",
  "m_yes": "Ja",
  "m_no": "Nee",
  "m_new": "Nieuw",
  "m_by": "door",
  "m_checkOut": "Bekijk",
  "m_templateGuide": "Tabel Richtlijnen",
  "m_createNewCg": "Nieuwe Uitdaging aanmaken",
  "m_createNewEvent": "Nieuw evenement aanmaken",
  "m_title": "Titel",
  "m_twisty": "Bochtig",
  "m_drag": "Drag",
  "m_city": "Stad",
  "m_rqLimit": "RQ limiet",
  "m_points": "Punten",
  "m_time": "Tijd",
  "m_timeToBeat": "Tijd om te verslaan",
  "m_uploadPrint": "Afdruk uploaden",
  "m_done": "Klaar",
  "m_sort": "Sorteren",
  "m_send": "Verzenden",
  "m_backHome": "Terug naar home",
  "m_any": "Elk",
  "m_rarity": "Zeldzaamheid",
  "m_car": "Auto | Auto's",
  "m_changed": "Verander",
  "m_noRecords": "Geen gegevens",
  "m_showOtherTracks": "Laat andere Circuits zien",
  "m_showMore": "Laat meer zien",
  "m_lastContribution": "Recente bijdragers",
  "m_nothingFound": "Niks gevonden",
  "m_changedCars": "veranderde auto's",
  "m_uploadSuccess": "Upload succesvol",
  "m_noData": "Geen data om te tonen",
  "m_noTimesFound": "Geen tijden gevonden",
  "m_logoutSuccess": "Succevol uitgelogd",
  "m_loginSuccess": "U bent ingelogd",
  "m_deleteSuccess": "Succesvol verwijderd",
  "m_saveSuccess": "Succesvol opgeslagen",
  "m_approveSuccess": "Succesvol goedgekeurd",
  "m_sentReview": "Ter beoordeling ingediend",
  "m_language": "Taal",
  "m_logout": "Uitloggen",
  "m_resetSavedHand": "Resetten naar beste oplossing",
  "m_showCustomTunes": "Laat aangepaste configuraties zien",
  "m_kingForceVertical": "Weergave naar verticaal veranderen",
  "p_userSentCgForAnalyse": "Dankjewel! Jouw ronde wordt geanalyseerd. Join onze Discord als je het erover wilt hebben.",
  "p_emptyRoundForUser": "Deze ronde is nog niet klaar. Je kan helpen met het maken en hem vervolgens ter beoordeling indienen.",
  "p_emptyRound2": "Deze ronde is nog niet klaar. Als je op deze ronde bent, dan kan je helpen om hem te includeren.",
  "p_emptyRoundLogin": "Log in om deze ronde to maken of neem contact op met een moderator op onze discord server:",
  "p_lockRoundPhrase": "Deze ronde versleutelen zodat jij hem kan bewerken",
  "p_modDoingRound": "{mod} is bezig met deze ronde",
  "p_patronsOnly": "Deze feature is alleen beschikbaar voor patrons (Tier {tier}).",
  "p_bestOfDescription": "Het geeft een lijst met de beste auto's voor de gegeven circuit en filter terug. Bijvoorbeeld:",
  "p_chartsDescription": "Het stopt alle opgeslagen data van een circuit in een tabel. Ter demonstratie:",
  "p_templateWarning": "Wees er zeker van dat jouw tabel bruikbaar is voor de gemeenschap of hij wordt niet goedgekeurd",
  "p_about1": "Dit project is gemaakt door TiagoXavi en is niet verwant aan Hutch Games Ltd.",
  "p_about2": "Voor problemen of suggesties, join de Discord server of schrijf een email (mighty.boy@topdrivesrecords.com).",
  "p_about3": "TDR is gratis voor iedereen. Je kan het project ondersteunen om het online te houden en nieuwe features te ontvangen.",
  "p_resetPassHelper": "Voer je email in om een link te ontvangen om je wachtwoord te herstellen",
  "p_resetPassIfExist": "Als er een gebruiker met deze email bestaat, is er een link gestuurd om het wachtwoord te herstellen.",
  "p_confirmationWillSent": "Een bevestigingsmail wordt gestuurd.",
  "p_checkYourEmailBox": "Controleer alstublieft uw inbox",
  "p_spamToo": "En ook uw spam-folder!",
  "p_somethingWrong": "Er is iets fout gegaan",
  "c_tag": null,
  "g_joinDiscord": "Join de TDR Discord server om te praten over verbeteringen voor jouw tabel of waarom jouw afgekeurd is.",
  "g_rule0": "Je tabel hoort een zinvolle context voor auto's te hebben. Zoals hetzelfde label, hetzelfde land, dezelfde banden, hetzelfde merk, dezelfde klasse... Of een context zoals Hutch doet voor evenement/uitdaging eisen.",
  "g_rule1": "Je hoort zoveel als mogelijke of alle auto's toe te voegen die binnen de context vallen. Voeg niet alleen auto's toe die je zelf wel hebt.",
  "g_rule2": "De tabel moet auto's met vergelijkbare statistieken vergelijken. Het is niet zinvol om RWD Perf met 4WD All-surface te vergelijken op droge asfalt circuits.",
  "g_rule3": "Mix alleen off-road met on-road circuits als daar een reden voor is.",
  "g_rule4": "Voeg niet alle circuits in de game toe aan de tabel. De app ondergaat dan prestatieproblemen.",
  "g_rule5": "Probeer vergelijkende circuits te vergelijken zoals drag races of circuits met dezelfde ondergrond.",
  "g_rule6": "Probeer de autos te sorteren op RQ of prestatie op de geselecteerde circuits.",
  "g_rule7": "De tabel hoeft niet alle tijden ingevuld te hebben, maar je mag wel invullen wat mogelijk is voordat je hem indient.",
  "g_rule8": "Gebruik een omschrijvende titel.",
  "g_rule9": "Kijk of er al een tabel met het zelfde doeleinde als de jouwe bestaat.",
  "c_topSpeed": "Top Snelheid",
  "c_class": null,
  "c_year": "Jaar | Jaren",
  "c_tyre": null,
  "c_country": "Land | Landen",
  "c_brand": "Merk | Merken",
  "s_dry": "Droog",
  "s_aspht": null,
  "s_wet": "Nat",
  "s_dirt": "Aarde",
  "s_gravel": "Grind",
  "s_ice": "Ijs",
  "s_sand": "Zand",
  "s_snow": "Sneeuw",
  "s_grass": "Gras",
  "c_handling": null,
  "c_drive": null,
  "c_clearance": null,
  "c_stock": "Standaard",
  "c_weight": "Gewicht",
  "c_fuel": "Brandstof",
  "c_seats": "Stoelen",
  "c_enginePos": "Motor positie",
  "c_bodyStyle": null,
  "c_prizeCar": "Prijs Auto",
  "c_prize cars": "Prijs Auto's",
  "c_non-prize cars": "Niet Prijs Auto's",
  "c_mid": null,
  "c_low": "Laag",
  "c_high": "Hoog",
  "c_performance": null,
  "c_standard": null,
  "c_all-surface": null,
  "c_off-road": null,
  "c_slick": null,
  "c_saloon": null,
  "c_roadster": null,
  "c_coupe": null,
  "c_hatchback": null,
  "c_suv": null,
  "c_convertible": null,
  "c_estate": null,
  "c_pickup": null,
  "c_mpv": null,
  "c_van": "Busje",
  "c_dragster": null,
  "c_twister": null,
  "c_all-rounder": null,
  "c_petrol": "Benzine",
  "c_diesel": null,
  "c_electric": "Electrisch",
  "c_hybrid": "Hybride",
  "c_bioethanol": null,
  "c_hydrogen": "Hydrogeen",
  "c_misc": null,
  "c_frontEngine": "Voorkant",
  "c_midEngine": "Midden",
  "c_mid-rearEngine": null,
  "c_mixedEngine": null,
  "c_rearEngine": "Achter",
  "c_tune": null,
  "t_drag100b": null,
  "t_drag100": null,
  "t_drag120": null,
  "t_drag150b": null,
  "t_drag150": null,
  "t_drag170": null,
  "t_drag200": null,
  "t_drag60b": null,
  "t_drag60": null,
  "t_mile1": null,
  "t_mile1r": null,
  "t_mile2": null,
  "t_mile4": null,
  "t_mile4r": null,
  "t_drag100150": null,
  "t_drag30130": null,
  "t_drag50150": null,
  "t_drag75125": null,
  "t_airplaneHangars": null,
  "t_airplaneSlalom": null,
  "t_smallRoad": null,
  "t_canyonTour": null,
  "t_carPark": null,
  "t_townSlalom": null,
  "t_townSprint": null,
  "t_csMed": null,
  "t_csSmall": null,
  "t_townTour": null,
  "t_cross": null,
  "t_fast": null,
  "t_fastr": null,
  "t_fieldSlalom": null,
  "t_figureEight": null,
  "t_forestRiver": null,
  "t_forest": null,
  "t_forestSlalom": null,
  "t_frozenLake": null,
  "t_frozenRivSla": null,
  "t_gForce": null,
  "t_gForcer": null,
  "t_hairpin": null,
  "t_hClimb": null,
  "t_hClimbr": null,
  "t_indoorKart": null,
  "t_kart": null,
  "t_draglshape": null,
  "t_mnGforce": null,
  "t_mnHairpin": null,
  "t_mnCityNarrow": null,
  "t_mnCity": null,
  "t_mnCityLong": null,
  "t_moto": null,
  "t_mtHairpin": null,
  "t_mtHill": null,
  "t_mtIncline": null,
  "t_mtSlalom": null,
  "t_mtTour": null,
  "t_mtTwisty": null,
  "t_northloop": null,
  "t_northloop1": null,
  "t_northloop2": null,
  "t_northloop3": null,
  "t_northloop4": null,
  "t_northloop5": null,
  "t_oceanSlalom": null,
  "t_oceanCity": null,
  "t_oceanHighway": null,
  "t_oceanLongDrag": null,
  "t_oceanParking": null,
  "t_oceanShortDrag": null,
  "t_rallyMed": null,
  "t_rallySmall": null,
  "t_riverSprint": null,
  "t_runwayDrag": null,
  "t_runwayDragSlalom": null,
  "t_runwayLoop": null,
  "t_serviceRoads": null,
  "t_slalom": null,
  "t_slalomr": null,
  "t_speedbump12km": null,
  "t_speedbump1km": null,
  "t_testBowl": null,
  "t_testBowlr": null,
  "t_tokyoBridge": null,
  "t_tokyoDrag": null,
  "t_tokyoGforce": null,
  "t_tokyoLoop": null,
  "t_tokyoOffRamp": null,
  "t_tokyoOverpass": null,
  "t_tCircuit": null,
  "t_tCircuitr": null,
  "t_tRoad": null,
  "t_waterDrag": null,
  "t_butte": null,
  "t_canyonLookout": null,
  "t_canyonDtRoad": null,
  "t_canyonButte": null,
  "t_lookout": null,
  "t_dtRoad": null,
  "m_newCar": "Nieuwe Auto",
  "m_carFinder": "Auto Zoeker",
  "p_eventsKingLogin": "Log-In om de best auto te zien voor elke zeldzaamheid",
  "m_newPhotos": "Nieuwe Foto's",
  "m_filterPage": "Filter Pagina",
  "m_vNewPhotos": "{version} Nieuwe Foto's",
  "c_name": "Naam",
  "p_beforeLeave": "Ben je zeker dat je wil weggaan?",
  "m_failed": "Gefaald",
  "m_openLimit": "Open Limiet",
  "m_relativeBest": "Relatief ten opzichte van het beste",
  "m_notCurrent": "Niet op dit moment",
  "m_daySelector": "Dag kiezer",
  "m_hidden": "Verstopt",
  "m_showPointsCgForce": "laat punten zien",
  "m_showBigCards": "Laat grote kaarten zien.",
  "p_emptyRoundVoteForUser": "Is alles goed met deze inzending?",
  "m_impossiblePack": "Dit pakje is onmogelijk",
  "m_impossible": "Onmogelijk",
  "m_cost": "Kost",
  "m_customizePack": "Pas het pakje aan",
  "p_eventsKingDescription": "Je kan maar 1 auto zien per zeldzaamheid. Hier is hoe de volledige lijst werkt.",
  "m_chartHideOutOfFilter": null,
  "m_meta": null,
  "m_race": null,
  "m_useTrackList": null,
  "m_modShowSuspectTimes": null,
  "m_newByCopy": null,
  "m_eventShowOnlyPicks": null,
  "m_eventForcePicks": null,
  "m_statsView": null,
  "m_multi": null,
  "m_multiTags": null,
  "m_enablePicks": null,
  "m_clearPicks": null,
  "m_findRqSavers": null,
  "m_homePointsToggle": null,
  "m_zoom": null,
  "m_review": null,
  "m_addReview": null,
  "m_clubs": null,
  "m_showContributors": null,
  "m_tracksetName": null,
  "m_tracksets": null,
  "m_reqs": null,
  "m_reqsName": null,
  "m_current": null,
  "m_saveDay": null,
  "m_createNewTrackset": null,
  "m_createNewReqs": null,
  "m_saveTrackset": null,
  "m_saveReqs": null,
  "m_saveOriginalOrder": null,
  "m_showOriginalOrder": null,
  "m_admin": null,
  "m_testPoints": null,
  "m_downTimes": null,
  "m_translationTool": null,
  "m_2xmultiplier": null,
  "m_cheatSheet": null,
  "m_simulateUntilGetAll": null,
  "m_simulateUntilGetOne": null,
  "m_simulate": null,
  "m_simulating": null,
  "m_impossibleRemoved": null,
  "m_count": null,
  "m_quantityOfCars": null,
  "m_success": null,
  "m_status": null,
  "m_stop": null,
  "m_probabilityPerOpen": null,
  "m_cumulativeProbability": null,
  "m_superLucky": null,
  "m_veryLucky": null,
  "m_goodLuck": null,
  "m_littleLucky": null,
  "m_badLuck": null,
  "m_worstLuck": null,
  "m_zeroLuck": null,
  "m_impossibleCondition": null,
  "m_permanents": null,
  "p_eventHiddenForPatreons": null,
  "c_hand": null,
  "c_speedster": null,
  "t_laguna": null,
  "t_lumberTwisty": null,
  "t_desertHill": null,
  "t_desertSmall": null,
  "t_desertBig": null,
  "t_desertRallyDirt": null,
  "t_dockAltCirc": null,
  "t_dockCirc": null,
  "t_dockCity": null,
  "t_dockGforceDrag": null,
  "t_dockKart": null,
  "t_dockUdrag": null,
  "t_lumberForest": null,
  "t_lumberRiver": null,
  "t_drag62": null,
  "t_drag124": null,
  "t_dealsGap1": null,
  "t_dealsGap2": null,
  "t_dealsGap3": null,
  "t_dealsGap4": null,
  "t_dealsGap5": null,
  "t_dealsGap": null,
  "t_dealsGapBack": null
}